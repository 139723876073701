import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import {
  useBreakpoint,
  Pagination,
  PaginationItem,
  PaginationCompact,
  PaginationDisplayedItemCount
} from '@one-thd/sui-atomic-components';
import { updateQueryParams } from '../product-results-helpers';
import { ProductResultsContext } from './ProductResultsContext';

import '../styles/results-pagination.scss';

const ResultsPagination = (props) => {
  const { data } = useContext(ProductResultsContext);
  const matches = useBreakpoint('sm');

  const { onPageChange, pageSize, path } = props;

  const { products = [], searchReport } = data?.searchModel || {};
  if (!products.length || !searchReport) return null;

  const totalProducts = searchReport?.totalProducts > 696
    ? 696
    : searchReport?.totalProducts;

  const actualProductCount = searchReport?.totalProducts;
  const startIndex = searchReport?.startIndex || 0;

  const currentPage = Math.round(startIndex / pageSize) + 1;
  const totalPages = Math.ceil(totalProducts / pageSize);

  if (Number.isNaN(totalPages)
  || Number.isNaN(pageSize)
  || totalPages === 1
  || totalPages === Infinity) return null;

  const onChangeWrapper = (event, page) => {
    event.preventDefault();
    if (onPageChange) {
      window.LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger('resetImpressionProvider');
      const url = updateQueryParams(path, [
        { paramName: 'Nao', paramValue: (page - 1) * pageSize }
      ]);
      onPageChange({ page, searchReport, url });
    }
  };

  const updateNao = (index = 0) => {
    let [_path, queryString = ''] = path.split('?');
    const isFirstPage = pageSize / index === pageSize;
    const multiplier = pageSize * (index - 1);
    const naoRegex = /Nao=[\w]+/;
    if (queryString.includes('Nao')) {
      queryString = (isFirstPage || index > totalPages)
        ? queryString.replace(/&amp;Nao=[\w]+/, '').replace(/Nao=[\w]+&amp;/, '').replace(/Nao=[\w]+/, '')
        : queryString.replace(naoRegex, `Nao=${multiplier}`);
    } else if (!isFirstPage && index > 0) {
      const ampersand = queryString.length && queryString[queryString.length - 1] !== '&' && multiplier ? '&' : '';
      queryString = queryString.concat(`${ampersand}Nao=${multiplier}`);
    }

    if (queryString) {
      queryString = `?${queryString}`;
    }

    return `${_path}${queryString}`;
  };

  const getPaginationItem = (item) => {
    // prevent last page being an anchor tag
    if (item.page === totalPages) {
      // eslint-disable-next-line react/jsx-props-no-spreading
      return <PaginationItem {...item} />;
    }
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <PaginationItem {...item} href={updateNao(item.page)} />;
  };

  return (
    <div className="sui-grow sui-pl-3" data-component="ResultsPagination">
      <div className="sui-relative
        sui-flex
        sui-items-center
        sui-gap-4
        sui-flex-col-reverse
        sm:sui-flex-row
        sm:sui-gap-0"
      >
        <PaginationDisplayedItemCount
          page={currentPage}
          itemsPerPage={pageSize}
          items={actualProductCount}
        />
        {matches.lesser
          ? (
            <PaginationCompact
              count={totalPages}
              page={currentPage}
              showFirstButton
              showLastButton
              onChange={onChangeWrapper}
              fullWidth={matches.lesser}
              renderItem={getPaginationItem}
            />
          )
          : (
            <Pagination
              count={totalPages}
              page={currentPage}
              onChange={onChangeWrapper}
              renderItem={getPaginationItem}
            />
          )}
      </div>
    </div>
  );
};

ResultsPagination.defaultProps = {
  pageSize: 24,
  path: ''
};

ResultsPagination.propTypes = {
  onPageChange: PropTypes.func.isRequired,
  pageSize: PropTypes.number,
  path: PropTypes.string
};

ResultsPagination.displayName = 'ResultsPagination';

export { ResultsPagination };
