import React, { useContext, useState, useEffect, useRef } from 'react';
import {
  FormController,
  TextField,
  IconButton,
  InputAdornment
} from '@one-thd/sui-atomic-components';
import { Search } from '@one-thd/sui-icons';
import { ExperienceContext } from '@thd-nucleus/experience-context';
import { CheckAvailabilityContext } from '../CheckAvailabilityContext';

export const PickupDrawerInputCart = () => {

  const {
    inputHeaderTitle,
    onFormSubmit
  } = useContext(CheckAvailabilityContext);

  const { channel } = useContext(ExperienceContext);

  const [input, setInput] = useState('');

  const onInputChange = (event) => {
    const sanitizeInput = event?.target?.value?.replace(/[^A-Za-z0-9, ]/, '');
    setInput(sanitizeInput);
  };

  const searchBarRef = useRef(null);
  const [isSearchBarClicked, setSearchBarIsClicked] = useState(false);
  const isFirstRender = useRef(true);

  useEffect(() => {
    if (channel === 'mobile' && searchBarRef?.current && !isFirstRender?.current) {
      searchBarRef.current.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    }
    isFirstRender.current = false;
  }, [isSearchBarClicked]);

  return (
    <div className="sui-w-full">
      <form
        className="sui-grid"
        onSubmit={(event) => {
          event.preventDefault();
          if (onFormSubmit) onFormSubmit(input);
        }}
      >
        <FormController>
          <TextField
            ref={searchBarRef}
            type="search"
            autoFocus
            id="check-availability__zip-code"
            placeholder="Zip Code, City, State, or Store Number"
            maxLength="25"
            autoComplete="off"
            onChange={onInputChange}
            onClick={() => setSearchBarIsClicked(!isSearchBarClicked)}
            value={input}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    type="submit"
                    icon={Search}
                    id="pickup-drawer-formButton"
                  />
                </InputAdornment>
              )
            }}
            label={inputHeaderTitle}
            status={null}
            disabled={false}
          />
        </FormController>
      </form>
    </div>
  );
};

PickupDrawerInputCart.propTypes = {};
