/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@thd-olt-component-react/button';
import { Col } from '@thd-olt-component-react/grid';
import classNames from 'classnames';
import '../../styles/refinement.scss';

const PriceRangeRefinement = (props) => {
  const [lowerBound, setLowerBound] = useState('');
  const [upperBound, setUpperBound] = useState('');
  const [lowerBoundFocused, setLowerBoundFocused] = useState(false);
  const [upperBoundFocused, setUpperBoundFocused] = useState(false);

  const {
    onChange, dimension, drawer, multiFacet, isPrimaryFilter, revision, isLowerBoundEntered, isUpperBoundEntered
  } = props;

  const clearInputFields = () => {
    setLowerBound('');
    setUpperBound('');
  };

  const isDisabled = useCallback(() => {
    return !(lowerBound.length || upperBound.length) || upperBound === '0';
  }, [lowerBound, upperBound]);

  const handleClick = useCallback((event) => {
    if (event) {
      event.preventDefault();
    }
    if (!isDisabled()) {
      onChange({
        refinement: {
          lowerbound: lowerBound,
          upperbound: upperBound,
          urlSearchParams: window.location.search,
          label: `$${lowerBound} - $${upperBound}`,
          deselectUrl: '?',
          refinementKey: null
        },
        dimension: {
          dimensionId: 'custom_price',
          label: 'Price'
        }
      });

      setLowerBoundFocused(false);
      setUpperBoundFocused(false);
      clearInputFields();
    }
  }, [lowerBound, upperBound, isDisabled, onChange]);

  const handleOnFocusFirstInput = useCallback((event) => {
    event.preventDefault();
    setLowerBoundFocused(true);
    setUpperBoundFocused(false);
  }, []);

  const handleOnFocusSecondInput = useCallback((event) => {
    event.preventDefault();
    setLowerBoundFocused(false);
    setUpperBoundFocused(true);
  }, []);

  const handleOnBlur = useCallback((event) => {
    event.preventDefault();

    setLowerBoundFocused(false);
    setUpperBoundFocused(false);
  }, []);

  const handleLowerBoundDropdownClick = useCallback((event) => {
    event.preventDefault();
    let minPriceInputDropdownValue = event.target.innerHTML.substring(1);
    setLowerBound(minPriceInputDropdownValue);
    handleOnFocusSecondInput(event);
    if (isPrimaryFilter) {
      isLowerBoundEntered(minPriceInputDropdownValue);
    }
  }, [handleOnFocusSecondInput]);

  const handleUpperBoundDropdownClick = useCallback((event) => {
    let maxPriceInputDropdownValue = event.target.innerHTML.substring(1);
    setUpperBound(maxPriceInputDropdownValue);
    handleOnBlur(event);
    if (isPrimaryFilter) {
      isUpperBoundEntered(maxPriceInputDropdownValue);
    }
  }, [handleOnBlur]);

  const refinementLabel = (refinement) => {
    return (refinement.label || '');
  };

  const lowerBoundPrices = dimension.refinements
    .filter((refinement) => refinementLabel(refinement).toUpperCase().indexOf('-') !== -1)
    .map((refinement, index) => (
      <li
        key={index}
        id="lowerBound"
        onMouseDown={handleLowerBoundDropdownClick}
        className="price-range__list"
      >
        {refinementLabel(refinement).substring(0, refinementLabel(refinement).indexOf(' '))}
      </li>
    ));

  const upperBoundPrices = dimension.refinements
    .filter((refinement) => refinementLabel(refinement).toUpperCase().indexOf('-') !== -1)
    .map((refinement, index) => (
      <li
        key={index}
        id="upperBound"
        onMouseDown={handleUpperBoundDropdownClick}
        className="price-range__list"
      >
        {refinementLabel(refinement).substring(refinementLabel(refinement).indexOf('-') + 2)}
      </li>
    ));

  const customRangeContainer = classNames(
    isPrimaryFilter ? 'custom-range-container' : 'refinement price-range'
  );

  const priceInputClassName = classNames('price-range__input', {
    'price-range__input--wide': drawer,
    'price-range__form--input-pf': isPrimaryFilter
  });

  const priceApplyBtnClassName = classNames('bttn-outline bttn-outline--dark price-range__bttn', {
    'price-range__bttn--medium': drawer
  });
  const priceGoBtnClassName = classNames({
    'price-range__go-bttn': !drawer,
    'price-range__go-bttn--small': multiFacet
  });
  const priceFormClasses = classNames('price-range__form', {
    'price-range__form--padding-right': multiFacet
  });
  const lowerBoundContainer = classNames({
    'price-range__form--lower-bound': drawer,
    'price-range__form--upper-bound-pf': isPrimaryFilter
  });
  const upperBoundContainer = classNames({
    'price-range__form--upper-bound': drawer,
    'price-range__form--upper-bound-pf': isPrimaryFilter
  });

  const spanText = classNames({ 'price-range__form--span-text': isPrimaryFilter });

  const priceRangeDropdownPf = classNames('price-range__container', {
    'price-range__dropdown_pf': isPrimaryFilter
  });

  const priceRangeUpperBoundDropdownPf = classNames('price-range__container--upper-bound', {
    'price-range__dropdown_pf': isPrimaryFilter,
    'price-range__upperBound__dropdown_pf': isPrimaryFilter
  });

  // to clear values of min-max container in primary filters
  useEffect(() => {
    setLowerBoundFocused(false);
    setUpperBoundFocused(false);
    clearInputFields();
  }, [revision]);

  // to make Apply btn clickable present in primary filters
  const handleLowerBoundValue = (event) => {
    let minPriceInputValue = (event.target.value || '').replace(/\D/, '');
    setLowerBound(minPriceInputValue);
    if (isPrimaryFilter) {
      isLowerBoundEntered(minPriceInputValue);
    }
  };

  // to make Apply btn clickable present in primary filters
  const handleUpperBoundValue = (event) => {
    let maxPriceInputValue = (event.target.value || '').replace(/\D/, '');
    setUpperBound(maxPriceInputValue);
    if (isPrimaryFilter) {
      isUpperBoundEntered(maxPriceInputValue);
    }
  };

  return (
    <Col className={customRangeContainer} nopadding={!drawer}>
      <form>
        <div className={priceFormClasses}>
          <div className={lowerBoundContainer}>
            <input
              autoComplete="off"
              className={priceInputClassName}
              placeholder="$ Min"
              name="lowerBound"
              aria-label="price-range-lowerBound"
              value={lowerBound}
              maxLength="5"
              onChange={handleLowerBoundValue}
              onFocus={handleOnFocusFirstInput}
              onBlur={handleOnBlur}
              type="tel"
            />
            {lowerBoundFocused ? (
              <div className={priceRangeDropdownPf}>
                <ul>{lowerBoundPrices}</ul>
              </div>
            ) : null}
          </div>
          <span className={spanText}>to</span>
          <div className={upperBoundContainer}>
            <input
              autoComplete="off"
              className={priceInputClassName}
              placeholder="$ Max"
              name="upperBound"
              aria-label="price-range-upperBound"
              value={upperBound}
              maxLength="5"
              onChange={handleUpperBoundValue}
              onFocus={handleOnFocusSecondInput}
              onBlur={handleOnBlur}
              type="tel"
            />
            {upperBoundFocused ? (
              <div className={priceRangeUpperBoundDropdownPf}>
                <ul>{upperBoundPrices}</ul>
              </div>
            ) : null}
          </div>
          {/* display Apply btn only in case of Secondary Filters */}
          { !isPrimaryFilter && (
            <Button
              dark={!isDisabled()}
              disabled={isDisabled()}
              inline
              outline
              onClick={handleClick}
              className={priceGoBtnClassName}
              data-testid="price-range-bttn"
            >
              {drawer ? 'Apply' : 'Go'}
            </Button>
          )}
        </div>
      </form>
    </Col>
  );
};

PriceRangeRefinement.propTypes = {
  dimension: PropTypes.shape({
    refinements: PropTypes.arrayOf(
      PropTypes.shape({})
    ),
  }),
  drawer: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  multiFacet: PropTypes.bool,
  isPrimaryFilter: PropTypes.bool,
  revision: PropTypes.number,
  isLowerBoundEntered: PropTypes.func.isRequired,
  isUpperBoundEntered: PropTypes.func.isRequired,
};

PriceRangeRefinement.defaultProps = {
  dimension: null,
  drawer: false,
  multiFacet: false,
  isPrimaryFilter: false,
  revision: 0
};

PriceRangeRefinement.displayName = 'PriceRangeRefinement';

export { PriceRangeRefinement };
