/* eslint-disable no-tabs */
import React, { useEffect, useState } from 'react';
import { useConfigService } from '@thd-nucleus/experience-context';
import { PayPalButtons } from '@paypal/react-paypal-js';
import { usePaypalInit } from '../hooks/usePaypalInit';
import { getTmxId } from '../utils/utils';
import {
  onApprove, createOrder, addAlert, getCartViewType, experienceType
} from '../utils/paypalUtils';
import {
  paypalSmartButtonClickNewRelic,
  paypalSmartButtonSuccessNewRelic,
  paypalSmartButonCancelNewRelic,
  paypalSmartButtonErrorNewRelic,
  paypalSmartButtonAddToCartFailNewRelic,
  paypalMissingData,
  paypalSmartButtonInitFailNewRelic,
} from '../utils/NewRelicHelper';
import { logAnalytics, PAYPAL_EVENTS, getAnalyticsEventName } from '../utils/AnalyticsHelper';
import { SIGN_IN, MYCHECKOUT_SAVED_PAYPAL, NUCLEUS_CHECKOUT_SAVED_PAYPAL } from '../constants/constants';

export function PaypalSmartButton(
  orderId,
  payInFour,
  hasSavedPaypal,
  cartRequest,
  itemCount,
  customer,
  page,
  fromPage,
  ffm,
  paypalButtonStyle,
  addToCart,
  itemPrice,
  checkoutType,
  triggerSubmit,
  isUpdatePaypal,
  triggerGetCart,
  initPaypal,
  isNucleusPaypalCheckout,
  disabled,
  customPaypalHandler
) {

  const buttonStyle = {
    label: paypalButtonStyle,
    color: 'gold',
    shape: 'rect',
    height: 40,
  };

  const isRegularPaypal = (fromPage === 'checkout');
  let returnUrl = '';

  const onPaypalApprove = async (paypalData) => {
    paypalSmartButtonSuccessNewRelic(fromPage, payInFour, orderId, paypalData);
    if (isUpdatePaypal) {
      triggerGetCart({ orderId, paypalData, checkoutType });
      return true;
    }
    return onApprove(
      paypalData,
      orderId,
      isNucleusPaypalCheckout,
      isRegularPaypal,
      returnUrl,
      triggerSubmit
    );
  };

  const createPaypalOrder = async (cartId) => {
    if (isNucleusPaypalCheckout || isRegularPaypal) {
      const eventName = getAnalyticsEventName(hasSavedPaypal, payInFour, fromPage, isRegularPaypal);
      logAnalytics({ eventName, eventData: { cartRequest, itemPrice, cartViewType: getCartViewType() } });
      return new Promise((resolve, reject) => {
        initPaypal({
          variables: {
            requestContext: {
              cartId, // populate from variables or global
              experience: { component: fromPage, type: experienceType(checkoutType) }, // populate from variables or global
              expTypeFilter: { type: ffm },
              tmxProfileId: getTmxId(),
            }
          },
        }).then((resp) => {
          if (resp?.initPaypal) {
            returnUrl = resp?.initPaypal?.returnUrl;
            resolve(resp?.initPaypal?.token);
          }
        }).catch((errorData) => {
          const error = errorData?.errors?.[0]?.extensions?.cart_info_errors?.[0];
          if (error) {
            if (error?.errorDescription?.errorCode === 'CART_INFO_ERR_001') {
              paypalSmartButtonInitFailNewRelic(
                error?.errorDescription, '');
              window.top.location = window?.location?.origin + '/' + SIGN_IN + '?pp_persisted=true';
            } else {
              paypalSmartButtonInitFailNewRelic(errorData, '');
              LIFE_CYCLE_EVENT_BUS.trigger('paypal-checkout-button.initError');
              console.log('Paypal create order error: ' + errorData);
            }
          } else {
            addAlert(true);
            console.error('Paypal create order error: ' + errorData);
          }
          reject(errorData);
        });
      });
    }
    if (cartId && fromPage && itemCount && ffm) {
      const retVal = createOrder(cartId, hasSavedPaypal, payInFour, fromPage, itemCount, ffm, cartRequest, itemPrice);
      return retVal;
    }
    return false;
  };

  const addTocart = (cartReq) => {
    logAnalytics({ eventName: PAYPAL_EVENTS.CART_STASH, eventData: cartReq });
    return addToCart({ cartRequest: cartReq })
      .then((resp) => {
        if (resp?.data?.addToCart) {
          return resp?.data?.addToCart?.cartId;
        }
        if (resp?.errors) {
          paypalSmartButtonAddToCartFailNewRelic(
            fromPage,
            resp?.errors?.[0]?.extensions.cart_info_errors?.[0],
            payInFour
          );
          LIFE_CYCLE_EVENT_BUS.trigger('paypal-checkout-button.addToCartError');
          return false;
        }
        return false;
      })
      .then((cartId) => {
        if (cartId) {
          return createPaypalOrder(cartId, ffm);
        }
        return false;
      })
      .catch((error) => {
        paypalSmartButtonAddToCartFailNewRelic(
          fromPage,
          error?.errors?.[0]?.extensions.cart_info_errors?.[0],
          payInFour
        );
        LIFE_CYCLE_EVENT_BUS.trigger('paypal-checkout-button.addToCartError');
        console.log(error);
      });
  };

  const triggerCreateOrder = async () => {
    paypalSmartButtonClickNewRelic(fromPage, payInFour, hasSavedPaypal, ffm, isUpdatePaypal);

    let cartReq = cartRequest;
    if (customPaypalHandler) {
      cartReq = await customPaypalHandler();
    }

    if (cartReq) {
      const retVal = addTocart(cartReq);
      return retVal;
    }

    if (orderId && orderId !== '' && fromPage === 'checkout') {
      const paypalToken = await createPaypalOrder(orderId);
      return paypalToken;
    }

    if (orderId && orderId !== '' && (fromPage === 'shoppingCart' || fromPage === 'addToCart')) {
      return createPaypalOrder(orderId);
    }

    const missingProps = fromPage === 'pip' ? 'cartRequest' : 'cartId';
    paypalMissingData(fromPage, payInFour, missingProps);
    return false;
  };

  const onCancel = (data) => {
    // This event hides the PayPal Button on PIP pages.
    LIFE_CYCLE_EVENT_BUS.trigger('add-to-cart.success', data);
    paypalSmartButonCancelNewRelic(fromPage, payInFour, orderId);
  };

  const onError = (error) => {
    try {
      paypalSmartButtonErrorNewRelic(fromPage, payInFour, error);
    } catch (newRelicError) {
      console.log('PayPal New Relic logging error: ', newRelicError);
    }
  };

  return (
    <PayPalButtons
      createOrder={triggerCreateOrder}
      onApprove={onPaypalApprove}
      onCancel={onCancel}
      onError={onError}
      fundingSource={hasSavedPaypal && payInFour ? 'paylater' : 'paypal'}
      style={buttonStyle}
      forceReRender={[cartRequest]}
      disabled={disabled}
    />
  );
}
