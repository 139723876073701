export const showMoreFilters = ({ dimensions = [], breadCrumbs = [] }) => {
  const dimensionCategories = dimensions.filter((dimension) => (dimension.label || '').toUpperCase() === 'CATEGORY');
  const breadCrumbsCategories = breadCrumbs.filter((breadCrumb) => (breadCrumb.dimensionName || '')
    && breadCrumb.dimensionName.toUpperCase() === 'CATEGORY');

  if ((breadCrumbsCategories === undefined || breadCrumbsCategories.length === 0)
    && (dimensionCategories !== undefined && dimensionCategories.length === 1)) {
    return true;
  }
  return false;
};

export const RATINGS_REFINEMENT_KEY = 'bwo5q';
export const PICK_UP_TODAY_REFINEMENT_KEY = '1z175a5';
export const HIGHLY_RATED_REFINEMENT_KEY = 'bwo5o';
export const FILTER_LABEL = 'All Filters';
export const FILTER_SORT_LABEL = 'Filter & Sort';

const MAX_REFINEMENTS = 5;
const BEST_RATED = 'Best-Rated';
const HIGH_RATED = 'Highly-Rated';

export const getNavParam = ({ nValue, breadCrumbs }) => {
  if (nValue) {
    return nValue.trim();
  }
  if (breadCrumbs) {
    const breadCrumbsCategories = (breadCrumbs.filter((breadCrumb) => (breadCrumb.dimensionName || '') && breadCrumb.dimensionName === 'Category')); // eslint-disable-line max-len

    const { url } = breadCrumbsCategories.pop() || {};

    if (!url) return null;

    return /\/N-(\w+)/.exec(url)[1];
  }
  return null;
};

export const getLoadingState = ({ data, loading, props, prevProps }) => {
  const varChange = (prevProps?.navParam !== props?.navParam)
    || (prevProps?.keyword !== props?.keyword)
    || (prevProps?.nao !== props?.nao)
    || (prevProps?.sortby !== props?.sortby)
    || (prevProps?.sortorder !== props?.sortorder)
    || (prevProps?.lowerbound !== props?.lowerbound)
    || (prevProps?.upperbound !== props?.upperbound);

  // loading is false
  if (!loading) return false;

  // loading is true, no data
  if (!data) return true;

  // loading is true, data, variable change
  if (data && varChange) return true;

  return false;
};

export const updateQueryParams = (path, params) => {
  const paramNames = params.map((p) => p.paramName); // eslint-disable-line id-length

  const [pathname, query = ''] = path.split('?');
  const queryParams = query.split('&').filter((p) => p); // eslint-disable-line id-length
  const updatedParams = [
    ...queryParams
      .filter((queryParam) => {
        const parts = queryParam.split('=');
        return paramNames.indexOf(parts[0]) === -1;
      }),
    ...params
      .filter((p) => p.paramValue) // eslint-disable-line id-length
      .map((param) => {
        return `${param.paramName}=${param.paramValue}`;
      })
  ].join('&');
  return pathname + '?' + updatedParams;
};

export const parseUrl = ({ path }) => {
  const parts = path.replace(/^\/render[^/]*/, '').split('?');
  const leftOfQuery = parts[0] || '';
  let queryString = parts[1] || '';

  let [_, context, category, ...pieces] = leftOfQuery.split('/');

  const slugs = [];
  let keyword;
  let nValue;

  if (queryString && (queryString.indexOf('lowerBound') > -1 && queryString.indexOf('upperBound') > -1)) {
    queryString = queryString.replace('lowerBound', 'lowerbound');
    queryString = queryString.replace('upperBound', 'upperbound');
  }

  if (context === 's') {
    keyword = category;
    category = 'search';
  }
  if (/^N-/.test(category)) {
    nValue = category;
    category = null;
  }
  (pieces || []).forEach((piece) => {
    if (!/^Ntt-|^Ntk-|N-5/.test(piece)) {
      slugs.push(piece);
    } else if (/^Ntt-/.test(piece)) {
      keyword = piece.substring(4, piece.length)?.split(/\+|%2B/).join(' ');
    } else if (/^N-/.test(piece)) {
      nValue = piece.substring(2, piece.length);
    }
  });

  const params = (queryString.match(/(\w+)=([^&]+)/g) || [])
    .map((val) => {
      const param = val.split('=');
      return {
        [param[0]]: param[1]
      };
    })
    .reduce((acc, next) => {
      const key = Object.keys(next)[0];
      return {
        ...acc,
        [key]: next[key]
      };
    }, {});

  if (params.storeIds) {
    params.storeIds = params.storeIds.split(',');
  }

  if (keyword) {
    if (keyword.match(/^https?:/)) {
      keyword = '';
    }
  }

  return {
    slugs,
    keyword,
    context,
    category,
    nValue,
    ...params
  };
};

export function getClearAllWithSort({ metadata, searchReport }) {
  let url = metadata.clearAllRefinementsURL || '';
  const { sortBy, sortOrder } = searchReport;
  const defaultSortOrder = sortOrder && sortOrder !== 'none' ? sortOrder : 'asc';

  if (defaultSortOrder && sortBy) {
    url += `?sortorder=${defaultSortOrder}&sortby=${sortBy}`;
  }
  return url;
}

const getCategories = (dimensions) => {
  const dimensionCategories = dimensions.filter((dimension) => (dimension.label || '') && (dimension.label.toUpperCase() === 'CATEGORY')); // eslint-disable-line max-len
  if (dimensionCategories === undefined || dimensionCategories.length === 0) {
    return [{ label: 'Category', dimensionId: '2', refinements: [] }];
  }
  return dimensionCategories;
};

export function sortDimensions({
  dimensions = [], searchReport = {}, metadata = {}, shiftCategoryDimension = false
}) {

  let getItFast = dimensions.filter((dimension) => (dimension.label || '') && (dimension.label.toUpperCase() === 'GET IT FAST')); // eslint-disable-line max-len

  const inStockOnly = dimensions.filter((dimension) => (dimension.label || '') && (dimension.label.toUpperCase() === 'AVAILABILITY')); // eslint-disable-line max-len

  const categories = getCategories(dimensions);

  const moreDimensions = dimensions.filter((dimension) => (dimension.label)
    && ['GET IT FAST', 'CATEGORY', 'AVAILABILITY'].indexOf(dimension.label.toUpperCase()) === -1);

  if (getItFast && !getItFast.length) {
    getItFast = [{
      label: 'Get It Fast',
      dimensionId: 'fakeDimension',
      refinements: [
        {
          label: 'Pick Up Today',
          refinementKey: 'fake',
          recordCount: 0,
          url: searchReport?.searchUrl || metadata?.canonicalUrl
        }]
    }];
  }
  if (shiftCategoryDimension) {
    return [
      ...categories,
      ...getItFast,
      ...inStockOnly,
      ...moreDimensions
    ];
  }
  return [
    ...getItFast,
    ...inStockOnly,
    ...categories,
    ...moreDimensions
  ];
}

export function getRefinementUrlWithSort({
  dimension, metadata, refinement, searchReport = {},
}) {
  const { dimensionId } = dimension;
  let url = refinement?.url?.slice(0);

  if (dimensionId === 'custom_price') {
    const parts = metadata.canonicalUrl.split('?');
    if (parts.length > 1) {
      url = metadata.canonicalUrl + `&upperbound=${refinement.upperbound}&lowerbound=${refinement.lowerbound}`;
    } else {
      url = metadata.canonicalUrl + `?NCNI-5&upperbound=${refinement.upperbound}&lowerbound=${refinement.lowerbound}`;

      const additionalParams = refinement.urlSearchParams ? refinement.urlSearchParams : '';
      const urlSearchParams = new URLSearchParams(additionalParams);
      if (urlSearchParams.has('catStyle') && urlSearchParams.get('catStyle').toUpperCase() === 'SHOWPRODUCTS') {
        url = `${url}&catStyle=${urlSearchParams.get('catStyle')}`;
      }
    }
  }
  if (url && (url.indexOf('lowerBound') > -1 && url.indexOf('upperBound') > -1)) {
    url = url.replace('lowerBound', 'lowerbound');
    url = url.replace('upperBound', 'upperbound');
  }

  const urlParts = url.split('/');
  const lastPart = urlParts.length ? urlParts[urlParts.length - 1] : '';
  const params = lastPart.split('?');
  const refinementParam = params.length > 1 ? params.pop() : null;
  const { sortBy, sortOrder } = searchReport;

  let updatedQuery = '';
  if (sortBy && sortOrder) {
    const isDefaultSort = sortBy === 'topsellers' && sortOrder === 'desc';
    if (!isDefaultSort) {
      updatedQuery = `?sortby=${sortBy}&sortorder=${sortOrder}`;
    }
  } else if (refinementParam) {
    updatedQuery = `?${refinementParam}`;
  }

  let updatedUrl = `${url}${updatedQuery}`;
  if (refinementParam) {
    updatedUrl = `${url}${updatedQuery.replace('?', '&')}`;
  }
  return updatedUrl;
}

export function hasDimensionName(dimensionName) {
  const DIMENSION_NAMES = ['MATERIAL', 'STYLE', 'COLOR', 'PATTERN', 'MEASUREMENTS', 'SIZE'];
  return DIMENSION_NAMES.indexOf(dimensionName) !== -1;
}

const getCrumbsByDimensionName = (crumbs, dimensionName) => (
  crumbs.filter((crumb) => (crumb.dimensionName || '').toUpperCase() === dimensionName.toUpperCase())
);
const getCrumbsByLabel = (crumbs, label) => (
  crumbs.filter((crumb) => (crumb.label || '').toUpperCase() === label.toUpperCase())
);

export function getBrowseSearchParam(crumbs = []) {
  let filterLabels = '';
  const visNavCategoryMap = new Map();
  let deletedSequences = [];

  const reviewRatings = getCrumbsByDimensionName(crumbs, 'REVIEW RATING');
  if (reviewRatings.length === 1 && reviewRatings[0].label === '5') {
    filterLabels += 'Best Rated ';
  }
  if (reviewRatings.length === 1 && reviewRatings[0].label === '4 & Up') {
    filterLabels += 'Highly Rated ';
  }

  // GET THE SOUND RATINGS
  const soundRatings = getCrumbsByDimensionName(crumbs, 'SOUND RATING RANGE (DECIBELS)');
  if (soundRatings && soundRatings.length === 1 && filterLabels.indexOf(soundRatings[0].label) === -1) {
    filterLabels += soundRatings[0].label + ' ';
  }

  // GET THE ECO FEATURES
  const ecoFeatures = getCrumbsByDimensionName(crumbs, 'ECO FEATURES');
  if (ecoFeatures && ecoFeatures.length === 1 && filterLabels.indexOf(ecoFeatures[0].label) === -1) {
    filterLabels += ecoFeatures[0].label + ' ';
  }

  // GET THE INDOOR OUTDOOR
  const indoorOutdoor = getCrumbsByDimensionName(crumbs, 'INDOOR/OUTDOOR');
  if (indoorOutdoor && indoorOutdoor.length === 1 && filterLabels.indexOf(indoorOutdoor[0].label) === -1) {
    filterLabels += indoorOutdoor[0].label + ' ';
  }

  // GET THE CORDLESS CORDED
  const cordlessCorded = getCrumbsByDimensionName(crumbs, 'CORDLESS/ CORDED');
  if (cordlessCorded && cordlessCorded.length === 1 && filterLabels.indexOf(cordlessCorded[0].label) === -1) {
    filterLabels += cordlessCorded[0].label + ' ';
  }

  // GET IN HOME DEPOT EVENTS
  const homeDepotEvents = getCrumbsByDimensionName(crumbs, 'THE HOME DEPOT EVENTS');
  if (homeDepotEvents.length === 1) {
    filterLabels += homeDepotEvents[0].label + ' ';
  }

  const categoryCrumbs = getCrumbsByDimensionName(crumbs, 'CATEGORY');
  const hasWashersAndDryersRefinement = categoryCrumbs.length > 0
    && getCrumbsByLabel(categoryCrumbs, 'WASHERS & DRYERS').length === 1;
  const stackableRefinements = getCrumbsByDimensionName(crumbs, 'STACKABLE');
  if (
    hasWashersAndDryersRefinement
    && stackableRefinements.length === 1
    && stackableRefinements[0].label.toUpperCase() === 'STACKABLE'
  ) {
    filterLabels += 'Stackable ';
  }

  const visualNavSequences = (crumbs || []).map((crumb) => {
    const dimensionName = (crumb.dimensionName || '').toUpperCase();
    const hasDimName = hasDimensionName(dimensionName);
    if (hasDimName) {
      const hasDupicateDimension = deletedSequences.indexOf(crumb.dimensionName) !== -1;
      if (hasDupicateDimension) {
        if (visNavCategoryMap.size) {
          visNavCategoryMap.delete(crumb.dimensionName);
        }
      } else {
        visNavCategoryMap.set(crumb.dimensionName, crumb.label);
        deletedSequences.push(crumb.dimensionName);
      }
    }
    return visNavCategoryMap;
  });

  // GET THE SIZE NAMES
  const sizeNames = crumbs.filter((crumb) => (crumb.dimensionName || '').toUpperCase().indexOf('SIZE') !== -1);
  if (sizeNames && sizeNames.length === 1 && filterLabels.indexOf(sizeNames[0].label) === -1) {
    filterLabels += sizeNames[0].label + ' ';
  }

  // GET THE MEASUREMENT NAMES
  const measurments = crumbs
    .filter((crumb) => (crumb.dimensionName || '').toUpperCase().indexOf('MEASUREMENTS') !== -1);
  if (measurments
    && measurments.length === 1
    && filterLabels.indexOf(measurments[0].label) === -1) {
    filterLabels += measurments[0].label + ' ';
  }

  // GET THE SHAPE NAMES
  const shapeNames = crumbs.filter((crumb) => (crumb.dimensionName || '').toUpperCase().indexOf('SHAPE') !== -1);
  if (shapeNames && shapeNames.length === 1 && filterLabels.indexOf(shapeNames[0].label) === -1) {
    filterLabels += shapeNames[0].label + ' ';
  }

  // GET THE COLOR NAMES
  const colorNames = crumbs.filter((crumb) => (crumb.dimensionName || '').toUpperCase().indexOf('COLOR') !== -1);
  if (colorNames && colorNames.length === 1 && filterLabels.indexOf(colorNames[0].label) === -1) {
    filterLabels += colorNames[0].label + ' ';
  }

  // GET THE PATTERN NAMES
  const patterns = crumbs.filter((crumb) => (crumb.dimensionName || '').toUpperCase().indexOf('PATTERN') !== -1);
  if (patterns && patterns.length === 1 && filterLabels.indexOf(patterns[0].label) === -1) {
    filterLabels += patterns[0].label + ' ';
  }

  // GET THE STYLE NAMES
  const styleNames = crumbs.filter((crumb) => (crumb.dimensionName || '').toUpperCase().indexOf('STYLE') !== -1);
  if (styleNames && styleNames.length === 1 && filterLabels.indexOf(styleNames[0].label) === -1) {
    filterLabels += styleNames[0].label + ' ';
  }

  // GET THE MATERIAL LABEL
  const materialNames = crumbs.filter((crumb) => (crumb.dimensionName || '').toUpperCase().indexOf('MATERIAL') !== -1);
  if (materialNames && materialNames.length === 1 && filterLabels.indexOf(materialNames[0].label)) {
    filterLabels += materialNames[0].label + ' ';
  }

  // GET THE BRAND NAMES
  const brandNames = crumbs.filter((crumb) => (crumb.dimensionName || '').toUpperCase() === 'BRAND');
  if (brandNames.length === 1 && filterLabels.indexOf(brandNames[0].label)) {
    filterLabels += brandNames[0].label + ' ';
  }

  // GET THE COMMERCIAL RESIDENTIAL
  const commercialFilter = getCrumbsByDimensionName(crumbs, 'COMMERCIAL / RESIDENTIAL');
  if (commercialFilter && commercialFilter.length === 1 && filterLabels.indexOf(commercialFilter[0].label) === -1) {
    filterLabels += commercialFilter[0].label + ' ';
  }

  visNavCategoryMap.forEach((value) => {
    if (filterLabels.indexOf(value) === -1) {
      filterLabels += value + ' ';
    }
  });

  // GET THE CATEGORY NAMES
  const categoryNames = crumbs.filter((crumb) => (crumb.dimensionName || '').toUpperCase() === 'CATEGORY');
  if (categoryNames.length > 0) {
    const categoryName = (categoryNames[categoryNames.length - 1] || '').label;
    // Only add category name to filterLabels if the brand name does not already contain the category name
    if (!filterLabels.toUpperCase().includes(categoryName.toUpperCase())) {
      filterLabels += categoryName + ' ';
    }
  }

  // GET IN STOCK AT STORE TODAY
  const pickUpToday = crumbs.find((crumb) => crumb?.refinementKey === PICK_UP_TODAY_REFINEMENT_KEY);
  const isFurniture = categoryNames.find((category) => category.label === 'Furniture');
  if (pickUpToday && !isFurniture && categoryNames.length > 0) {
    filterLabels += 'In Stock Near Me';
  }

  // GET INSTALLATION DIMENSIONS
  const installationNames = crumbs.filter((crumb) => (crumb.dimensionName || '').toUpperCase() === 'INSTALLATION');
  if (installationNames.length === 1) {
    filterLabels += ' - ' + installationNames[0].label;
  }

  const letUsDoItForYouLabels = crumbs
    .filter((crumb) => (crumb.dimensionName || '').toUpperCase() === 'LET US DO IT FOR YOU');
  if (letUsDoItForYouLabels.length === 1) {
    filterLabels += ' - ' + letUsDoItForYouLabels[0].label;
  }

  return filterLabels;
}

export function getCustomPriceRange(lowerbound, upperbound) {
  let lbound = parseInt(lowerbound, 10) || undefined;
  let ubound = parseInt(upperbound, 10) || undefined;

  lbound = !lbound && !!ubound ? 0 : lbound;
  // pass the ubound as received (undef), if not supplied by user
  ubound = !ubound && !!lbound ? upperbound : ubound;

  if (ubound && lbound > ubound) [lbound, ubound] = [ubound, lbound];
  return { lbound, ubound };
}

export function getRefinementIndex(refinement, dimension) {
  const { refinements = [] } = dimension;
  let index = refinements.findIndex((ref) => {
    return ref.label === refinement.label;
  });
  return index + 1;
}

export function getRatedValue(breadCrumbs, label) {
  return breadCrumbs.filter((crumb) => crumb.dimensionName
    && crumb.dimensionName.toUpperCase() === 'REVIEW RATING'
    && crumb.label
    && crumb.label === label).length > 0;
}

export const hasComparator = (refinement) => {
  const label = refinement?.label;
  return label ? ['Less', 'Greater', '<', '>', 'Other'].some((substring) => label.includes(substring)) : false;
};

export const hasRange = (refinement) => {
  const label = refinement?.label;
  return label ? label.includes('-') : false;
};

export const getRefinementLabel = (refinement) => {
  if (refinement && typeof refinement === 'object') {
    const label = refinement.label;
    if (hasComparator(refinement)) {
      return label.replace(/\D/g, '');
    }
    return label;
  }
  return '';
};

export const isSeries = (sortedRefinements) => {
  let isContinuous = true;
  let refinementsArr = [...sortedRefinements];

  // check to see if range comparison is applicable to all applied refinement
  if (sortedRefinements.every((ref) => ref.label.includes('-'))) {
    refinementsArr.every((el, index, arr) => {
      const curr = getRefinementLabel(el);
      // if there is no previous refinement, set the current refinement as the comparison
      const prev = getRefinementLabel((arr[index - 1])) || curr.split(' ')[0];

      isContinuous = !(prev.split(' ').slice(-1)[0] !== curr.split(' ')[0] && curr !== '');
      // reference the value of isContinuous to determine whether or not the function should continue iterating
      // refinementsArr
      // the function will eject once isContinuous is assessed as false
      return isContinuous;
    });
  }
  // and finally, return the value so to the original call
  return isContinuous;
};

export const getRefinementUnit = (dimension) => {
  const label = dimension?.label;
  const index = label ? label.indexOf('(') : -1;
  const unit = index >= 0
    ? ` ${label.substring(index + 1, label.length - 1)}`
    : '';
  return unit;
};

export const getAppliedRefinementsRange = ({
  refinements,
  dimension = {},
  label
}) => {
  if (dimension && refinements && Array.isArray(refinements)) {
    const initialRefinement = refinements[0];
    const endRefinement = refinements.slice(-1)[0];

    const unit = getRefinementUnit(dimension);

    const startRange = hasComparator(initialRefinement)
      ? initialRefinement.label : initialRefinement.label.split(' ')[0];

    const endRange = hasComparator(endRefinement)
      ? endRefinement.label : endRefinement.label.split(' ').slice(-1)[0];

    let refinementLabel;
    if (endRefinement.label.includes('Greater')) {
      refinementLabel = `${startRange}${unit} - ${endRange.split(' ').splice(1, 0, unit).join(' ')})`;
    } else {
      refinementLabel = !endRefinement.label.includes('Other')
        ? `${startRange}${unit} - ${endRange}${unit}` : `${startRange}${unit} - ${endRange}`;
    }

    let url = initialRefinement.url;
    refinements.forEach((ref) => {
      const navParam = ref.label.replace('-', '').split(' ').filter((param) => param !== '').join('-') + '/';
      const nValue = `Z${ref.refinementKey}`;
      // clean up url
      url = url.replace(navParam, '');
      url = url.replace(nValue, '');
    });

    return {
      dimensionName: label,
      refinementLabel,
      url,
    };
  }

  return null;
};

export const parseLabel = (label) => {
  if (typeof label === 'string' && label.split(' ').length === 1) return parseFloat(label);
  return '';
};

export const sortRefinements = (currentRef, nextRef) => {
  if (currentRef || nextRef) {

    if (currentRef.label.toUpperCase().includes('GREATER') || currentRef.label.startsWith('>')) return 1;
    if (currentRef.label.toUpperCase().includes('LESS') || currentRef.label.startsWith('<')) return -1;

    const unitValueA = parseLabel(getRefinementLabel(currentRef).split(' ')[0]);
    const unitValueB = parseLabel(getRefinementLabel(nextRef).split(' ')[0]);
    return unitValueA - unitValueB;
  }
  return 0;
};

export const getAppliedRefinements = (appliedDimensions = [],
  isGetItFastOnPlp = false) => {
  let appliedRefinements = [];
  // eslint-disable-next-line
  appliedDimensions?.forEach((dimension) => {
    let { label, refinements, isNumericFilter, } = dimension;
    if (label === 'Special Values') label = 'Price';

    if (isNumericFilter) {
      let sortedRefinements = [...refinements].sort(sortRefinements);
      // check to see if a user inputted range is a series (ie. the next refinement starts with the upperbound value
      // of the last)
      if (sortedRefinements.length > 1 && isSeries(sortedRefinements)) {
        const appliedRefinementRange = getAppliedRefinementsRange({
          refinements: sortedRefinements,
          dimension,
          label
        });
        if (appliedRefinementRange) {
          appliedRefinements.push(appliedRefinementRange);
          return;
        }
      }
    }

    refinements.forEach((refinement) => {
      let { label: refinementLabel, refinementKey } = refinement;
      if (refinementLabel === '5' && refinementKey === RATINGS_REFINEMENT_KEY) {
        refinementLabel = 'Best Rated';
      }
      if (refinementLabel === '4 & Up' && refinementKey === HIGHLY_RATED_REFINEMENT_KEY) {
        refinementLabel = 'Highly Rated';
      }
      appliedRefinements.push({ dimensionName: label, refinementLabel, ...refinement });
    });
  });
  return appliedRefinements;
};

const getDimension = (dimensionName, _dimensions) => {
  let otherDimensions = [];
  let dimensionList = _dimensions.filter((dimension) => {
    let isIncluded = dimension.label.includes(dimensionName);
    if (!isIncluded) {
      otherDimensions.push(dimension);
    }
    return isIncluded;
  });
  return [dimensionList, otherDimensions];
};

export const sortAscending = (refinementA, refinementB) => {
  if (refinementA.label.toUpperCase() > refinementB.label.toUpperCase()) {
    return 1;
  }
  if (refinementA.label.toUpperCase() < refinementB.label.toUpperCase()) {
    return -1;
  }
  return 0;
};

export const decodeBase36RefinementKey = (refinementKeys) => {
  return refinementKeys.map((refinementKey) => parseInt(refinementKey, 36));
};

export const encodeBase36RefinementIds = (refinementIds) => {
  return refinementIds.map((refinementId) => refinementId.toString(36));
};

export const sortRefinementKeys = (refinements) => {
  const cleanRefienementList = refinements.filter((refinement) => refinement !== undefined && refinement !== null);
  let decodedRefinements = decodeBase36RefinementKey(cleanRefienementList);
  decodedRefinements.sort((valueA, valueB) => valueA - valueB);
  return encodeBase36RefinementIds(decodedRefinements);
};

export const getRefinementsByDimension = (appliedDimensions, dimension) => {
  return appliedDimensions?.find((appliedDimension) => appliedDimension.label === dimension.label)?.refinements || [];
};

// sort based on the priroity defined for seo
export const sortSelectedDimensions = (selectedDimensions) => {
  let otherDimensions;
  // get Review Ratings
  const [reviewRatings, excludeReviewRatings] = getDimension('Review Rating', selectedDimensions);
  const priority = reviewRatings[0]?.refinements.find(
    (ref) => ref.label === '5' || ref.label === '4 & Up');
  let priorityDimension = reviewRatings;
  if (priority === undefined) {
    priorityDimension = [];
    /* if rating is not 5 or 4 & up it need to go at the end of the list
    that's why it is added back to the list here */
    excludeReviewRatings.push(...reviewRatings);
  }
  // return brand
  const [brand, excludeBrand] = getDimension('Brand', excludeReviewRatings);
  // return color related
  const [color, excludeColor] = getDimension('Color', excludeBrand);
  color.sort(sortAscending);
  // return finsh
  const [finish, excludeFinish] = getDimension('Finish', excludeColor);
  finish.sort(sortAscending);
  // return size
  const [size, excludeSize] = getDimension('Size', excludeFinish);
  size.sort(sortAscending);
  // rest of diemensions
  const other = excludeSize.filter((dimension) => {
    if (!dimension.label.includes('Brand') && !dimension.label.includes('Color')
    && !dimension.label.includes('Finish') && !dimension.label.includes('Size')
    && (!dimension.label.includes('Review Rating')
    || (dimension.label.includes('Review Rating') && priority == null))) {
      return true;
    }
    return false;
  });
  other.sort(sortAscending);
  return [
    ...priorityDimension,
    ...brand,
    ...color,
    ...finish,
    ...size,
    ...other
  ];
};

export const getAppliedDimensions = (dimensionsList) => {
  if (Array.isArray(dimensionsList)) {
    return dimensionsList.map((dimension) => {
      return {
        label: dimension.label,
        refinements: dimension.refinements.slice()
      };
    });
  }
  return [];
};

const hasLessThan = (refinement) => {
  return ['Less', '<'].some((substring) => refinement.label.includes(substring));
};

const hasGreaterThan = (refinement) => {
  return ['Greater', '>'].some((substring) => refinement.label.includes(substring));
};

export const getRefinementRangeAndUrl = ({
  drawer = false,
  isMobile,
  lowerBound,
  metadata = {},
  multiFacet,
  refinements = [],
  upperBound,
}) => {

  let refinementRange = [];
  let url = metadata?.canonicalUrl;

  refinements.forEach((refinement) => {
    // preemptively add delimiter to refinement key
    let delimitedRefinementKey = `Z${refinement.refinementKey}`;
    // clears any previously added refinements from the canonical url
    url = url.replace(delimitedRefinementKey, '');
    let refinementGreaterOrLessThan = '';

    if (hasLessThan(refinement)) {
      refinementGreaterOrLessThan = parseLabel(getRefinementLabel(refinement)) - 1;
    }

    if (hasGreaterThan(refinement)) {
      refinementGreaterOrLessThan = parseLabel(getRefinementLabel(refinement)) + 1;
    }
    // ensure refinement start range is being accounted for
    let refinementUnitMeasurement = refinementGreaterOrLessThan || parseLabel(getRefinementLabel(refinement)
      .split(' ')[0]);

    // round range bounds to the nearest multiple of 5
    const min = hasRange(refinement) ? Math.floor(lowerBound / 5) * 5 : lowerBound;
    // decrement measurement so it's nominally considered for range value
    const max = hasRange(refinement) ? (Math.ceil(upperBound / 5) * 5) - 1 : upperBound;

    if (refinementUnitMeasurement && (refinementUnitMeasurement <= max && refinementUnitMeasurement >= min)) {
      if ((!drawer || isMobile) && !multiFacet) {
        refinementRange.push(delimitedRefinementKey);
      } else {
        const copiedRefinement = { ...refinement };
        refinementRange.push(copiedRefinement);
      }
    }
  }
  );

  return { refinements: refinementRange, url };
};

export const isRefinementActive = (refinementKey, currentSelectedRefinements) => {
  return Array.isArray(currentSelectedRefinements)
    && currentSelectedRefinements.some((refinement) => refinement.refinementKey === refinementKey);
};

export const findCustomPriceRange = (priceRefinements = []) => {
  let customPriceRange = priceRefinements
    .findLast((refinement) => (refinement.lowerbound && refinement.upperbound) || refinement.refinementKey === null);
  if (customPriceRange === undefined) {
    return [];
  }
  if (customPriceRange?.lowerbound || customPriceRange?.upperbound) {
    return [customPriceRange?.lowerbound, customPriceRange?.upperbound];
  }
  if (customPriceRange?.label.toLowerCase().includes('and up')) {
    let values = customPriceRange.label.split(' ');
    return [values[0].trim().replace('$', ''), ''];
  }
  let values = customPriceRange?.label.split('-');
  return [values[0].trim().replace('$', ''), values[1].trim().replace('$', '')];
};

// get dimensions from where refinements' labels are taken to be added to the URL
export const getDimensionsForUrl = (dimensions = []) => {
  if (!Array.isArray(dimensions)) return [];
  return dimensions.filter((dim, index) => {
    if (dim.label !== 'Price' && dim.label !== 'Category'
      && (dim.label !== 'Review Rating'
        || (dim.label === 'Review Rating'
          && (dim.refinements[0].label === '5' || dim.refinements[0].label === '4 & Up') && index === 0))
      && dim.label !== 'Saving by Percentage Off') {
      return true;
    }
    return false;
  });
};

/**
 * get refinements' labels that will be added to the URL and
 * rerplaces spaces with '-'
 * */
export const getRefinemntsLabelsForUrl = (refinements = []) => {
  let skipOne = 1;
  if (refinements.length === 0) {
    return [];
  }
  let refinementLabels = refinements.map((refinement) => {
    let labelNoSpace = refinement.label.replace(/ /g, '-').replace(/#/g, '');
    if (labelNoSpace === '5') {
      skipOne = 0;
      return BEST_RATED;
    } if (labelNoSpace === '4-&-Up') {
      skipOne = 0;
      return HIGH_RATED;
    }
    return labelNoSpace;
  });

  return refinementLabels.slice(0, MAX_REFINEMENTS - skipOne);
};
