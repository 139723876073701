/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { extend } from '@thd-nucleus/data-sources';
import { Col, Row } from '@thd-olt-component-react/grid';
import { Checkbox } from '@thd-olt-component-react/checkbox';
import { FitCompatibility } from '@thd-olt-component-react/fit-compatibility';
import { StandardRefinement } from '../Refinements/StandardRefinement';
import { OnDisplayRefinement } from '../Refinements/OnDisplayRefinement';
import { PriceRangeRefinement } from '../Refinements/PriceRangeRefinement';
import { sortRefinements, isRefinementActive } from '../../product-results-helpers';
import '../../styles/dimension.scss';

class StandardDimension extends Component {
  static dimensionRef = React.createRef(null);

  state = {
    seeAll: false,
    collapsed: false,
    filterInput: ''
  };

  componentDidMount() {
    this.setState({ collapsed: this.props.collapsed });
  }

  componentDidUpdate(prevProps) {
    if (this.props.collapsed !== prevProps.collapsed) {
      this.setState({ collapsed: this.props.collapsed }); // eslint-disable-line react/no-did-update-set-state
      if (!this.props.collapsed && typeof window !== 'undefined') {
        this.scrollIntoView();
      }
    }
  }

  scrollIntoView = () => StandardDimension.dimensionRef?.current?.scrollIntoView();

  onRefinementFilter = (event) => {
    event.preventDefault();
    this.setState({
      filterInput: event.target.value
    });
  };

  handleSeeAll = (event) => {
    event.preventDefault();
    this.setState((state) => {
      return {
        seeAll: !state.seeAll
      };
    });
  };

  handleCollapse = (event) => {
    event.preventDefault();
    this.setState((state) => {
      return {
        collapsed: !state.collapsed
      };
    });
  };

  handleOpenFitCompatibility = (event) => {
    event.preventDefault();
    this.props.onFitCompatibilityClick();
  };

  getRefinementId = (refinement, refinementIndex) => {
    const { refinementKey } = refinement;
    return this.props.mini
      ? `${refinementKey}-${refinementIndex}-mini`
      : `${refinementKey}-${refinementIndex}`;
  };

  render() {
    const {
      collapsed,
      filterInput,
      seeAll
    } = this.state;

    const {
      appliedRefinements,
      dimension,
      drawer,
      filter,
      mini,
      onChange,
      onDisplayData,
      enableMultiStore,
      priceRange,
      toggleSwitch,
      onMultiStoreSelectionChange,
      multiStoreSelection,
      multiFacet,
      isMobile,
      isActive,
      fitCompatibility
    } = this.props;

    const {
      dimensionId,
      label,
      refinements
    } = dimension;

    if (label.toUpperCase() === 'AVAILABILITY'
      && onDisplayData.isStoreDisplay === 'true') {
      return null;
    }

    const dimensionClassName = classNames('dimension', {
      'dimension--mini': mini,
      'dimension--drawer': drawer,
      'dimension--padding': multiFacet
    });

    const wrapperClassName = classNames({
      'dimension--collapsed': collapsed
    });

    const groupClassName = classNames('dimension__group grid', {
      'dimension__group--all': !collapsed && seeAll,
      'dimension__group--mini': mini,
      'dimension__group--mini--main-filter-wrapping':
        mini && ((multiFacet && isMobile) || !multiFacet) && label?.toUpperCase() === 'GET IT FAST',
      'dimension__group--mini--main-filter': mini && multiFacet && !isMobile && label?.toUpperCase() === 'GET IT FAST',
      'dimension__group--scroll': !mini,
      'dimension__group--drawer-scroll': drawer && (label?.toUpperCase() !== 'GET IT FAST' || multiFacet)
    });

    const checkboxClassName = classNames('dimension__item', {
      'dimension__item--hidden': !seeAll && !drawer,
      'dimension__item--fullwidth': multiFacet
    });

    const caretClassName = classNames({
      'dimension__caret--align': drawer
    });

    const borderClassName = classNames('dimension__box', {
      'dimension__box--line': drawer && !collapsed && !multiFacet,
      'dimension__box--padding-right': multiFacet
    });

    const filterClassName = classNames('dimension__filter', {
      'dimension__filter--align': drawer,
      'dimension__filter--margin-small': multiFacet
    });

    const dimensionLabelClassName = classNames('dimension__label', {
      'dimension__label--padding': !drawer,
      'dimension--active': isActive && drawer && multiFacet
    });

    const shadowClassName = classNames({ 'dimension__box--shadow': drawer && refinements?.length > 5 });

    return (
      <div className={dimensionClassName} ref={!this.props.collapsed ? StandardDimension.dimensionRef : null}>
        {!toggleSwitch && (
          <Row className={shadowClassName}>
            {!mini && (
              <Row>
                <a className={borderClassName} href="#" onClick={this.handleCollapse}>
                  <Col fallback="9">
                    <h2 className={dimensionLabelClassName} data-group={label}>{label}</h2>
                  </Col>
                  <Col fallback="3" className="dimension__caret--align">
                    <img
                      className={collapsed ? 'dimension__caret--collapsed' : 'dimension__caret'}
                      alt="caret-icon"
                      src="https://assets.thdstatic.com/images/v1/caret-grey.svg"
                      height="15"
                      width="9"
                    />
                  </Col>
                  {appliedRefinements && (
                    <Col nopadding>
                      <div className="dimension__applied-refinements">
                        {appliedRefinements}
                      </div>
                    </Col>
                  )}
                </a>
              </Row>
            )}
            <Row className={wrapperClassName}>
              {filter && (
                <input
                  placeholder="Search"
                  className={filterClassName}
                  value={filterInput}
                  type="text"
                  onChange={this.onRefinementFilter}
                />
              )}
              {priceRange && (
                <PriceRangeRefinement
                  dimension={dimension}
                  onChange={onChange}
                  drawer={drawer}
                  multiFacet={multiFacet}
                />
              )}
              {fitCompatibility && (
                <div
                  role="button"
                  tabIndex={0}
                  aria-label="fit-compatibility"
                  className="sui-p-1 sui-pb-0 sui-ml-1"
                  onClick={this.handleOpenFitCompatibility}
                >
                  <FitCompatibility
                    type="MINI"
                    drawerPosition="left"
                    imageCacheEnabled
                  />
                </div>
              )}
              <Checkbox.Group
                className={groupClassName}
                key={dimensionId}
                data-instoredisplay="true"
              >
                {
                  refinements && refinements
                    .filter((ref) => (ref.label || '').toUpperCase().indexOf(filterInput.toUpperCase()) !== -1)
                    .sort(sortRefinements)
                    .map((refinement, refinementIndex) => {
                      const id = this.getRefinementId(refinement, refinementIndex);
                      const active = isRefinementActive(refinement.refinementKey, this.props.selectedRefinements);
                      if (refinement.label.toUpperCase() === 'PICK UP TODAY'
                        && onDisplayData.isStoreDisplay === 'true') {
                        return (
                          <OnDisplayRefinement
                            checkboxClassName={checkboxClassName}
                            dimension={dimension}
                            id={id}
                            key={id}
                            onChange={onChange}
                            refinement={refinement}
                            toggleSwitch={toggleSwitch}
                            onMultiStoreSelectionChange={onMultiStoreSelectionChange}
                            multiStoreSelection={multiStoreSelection}
                            enableMultiStore={enableMultiStore}
                            onDisplayData={onDisplayData}
                            multiFacet={multiFacet}
                          />
                        );
                      }
                      return (
                        <StandardRefinement
                          checkboxClassName={checkboxClassName}
                          dimension={dimension}
                          id={id}
                          key={id}
                          onChange={onChange}
                          enableMultiStore={enableMultiStore}
                          refinement={refinement}
                          toggleSwitch={toggleSwitch}
                          onMultiStoreSelectionChange={onMultiStoreSelectionChange}
                          multiStoreSelection={multiStoreSelection}
                          onDisplay={onDisplayData.onDisplay}
                          multiFacet={multiFacet}
                          active={active}
                        />
                      );
                    })
                }
              </Checkbox.Group>
              {refinements && refinements
                .filter((ref) => (ref.label || '').toUpperCase().indexOf(filterInput.toUpperCase()) !== -1)
                .length > 5 && !drawer && (
                <a
                  className="dimension__see-all"
                  href="#"
                  onClick={this.handleSeeAll}
                >
                  {!seeAll ? '+ View All' : '- View Less'}
                </a>
              )}
            </Row>
          </Row>
        )}
        {toggleSwitch && (
          <Row>
            <div className="dimension__box dimension__box--space">
              <div className="dimension__label--align">
                <h2 className={dimensionLabelClassName} data-group={label}>{label}</h2>
              </div>
              <div className="dimension__caret--align">
                {
                  refinements && refinements
                    .map((refinement, refinementIndex) => {
                      const id = this.getRefinementId(refinement, refinementIndex);
                      const active = isRefinementActive(refinement.refinementKey, this.props.selectedRefinements);
                      if (refinement.label.toUpperCase() === 'PICK UP TODAY'
                        && onDisplayData.isStoreDisplay === 'true') {
                        return (
                          <OnDisplayRefinement
                            checkboxClassName={checkboxClassName}
                            dimension={dimension}
                            id={id}
                            key={id}
                            onChange={onChange}
                            refinement={refinement}
                            toggleSwitch={toggleSwitch}
                            onMultiStoreSelectionChange={onMultiStoreSelectionChange}
                            multiStoreSelection={multiStoreSelection}
                            enableMultiStore={enableMultiStore}
                            onDisplayData={onDisplayData}
                          />
                        );
                      }
                      return (
                        <StandardRefinement
                          checkboxClassName={checkboxClassName}
                          dimension={dimension}
                          id={id}
                          key={id}
                          onChange={onChange}
                          refinement={refinement}
                          toggleSwitch={toggleSwitch}
                          onMultiStoreSelectionChange={onMultiStoreSelectionChange}
                          multiStoreSelection={multiStoreSelection}
                          enableMultiStore={enableMultiStore}
                          onDisplay={onDisplayData.onDisplay}
                          multiFacet={multiFacet}
                          active={active}
                        />
                      );
                    })
                }
              </div>
            </div>
          </Row>
        )}
      </div>
    );
  }
}

StandardDimension.propTypes = {
  appliedRefinements: PropTypes.string,
  collapsed: PropTypes.bool,
  dimension: PropTypes.shape({
    dimensionId: PropTypes.string,
    label: PropTypes.string,
    refinements: PropTypes.arrayOf(
      PropTypes.shape({})
    ),
  }).isRequired,
  drawer: PropTypes.bool,
  filter: PropTypes.bool,
  fitCompatibility: PropTypes.bool,
  mini: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onFitCompatibilityClick: PropTypes.func,
  onMultiStoreSelectionChange: PropTypes.func,
  enableMultiStore: PropTypes.bool,
  priceRange: PropTypes.bool,
  multiStoreSelection: PropTypes.arrayOf(PropTypes.string),
  onDisplayData: PropTypes.shape({
    onDisplay: PropTypes.string,
    onDisplayChange: PropTypes.func,
    isStoreDisplay: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.bool
    ]),
    itemCount: PropTypes.string
  }),
  toggleSwitch: PropTypes.bool,
  multiFacet: PropTypes.bool,
  isMobile: PropTypes.bool,
  isActive: PropTypes.bool,
  selectedRefinements: PropTypes.arrayOf(PropTypes.shape({})),
};

StandardDimension.defaultProps = {
  appliedRefinements: null,
  collapsed: false,
  drawer: false,
  filter: false,
  fitCompatibility: false,
  onFitCompatibilityClick: () => null,
  mini: false,
  onDisplayData: {
    onDisplay: 'false',
    onDisplayChange: null,
    isStoreDisplay: 'false',
    itemCount: null
  },
  priceRange: false,
  toggleSwitch: false,
  enableMultiStore: false,
  onMultiStoreSelectionChange: null,
  multiStoreSelection: null,
  multiFacet: false,
  isMobile: false,
  isActive: false,
  selectedRefinements: null,
};

StandardDimension.displayName = 'StandardDimension';

StandardDimension.dataModel = extend(StandardRefinement);

export { StandardDimension };
