/* eslint-disable max-len */

const DUAL_PATH_CATEGORIES = {
  brio_inaisle_sfi_generalfs: 'DEFAULT_SERVICE',
  brio_inaisle_sfi_twht: 'TANKLESS_WATER_HEATER',
  brio_inaisle_sfi_whtr: 'TANK_WATER_HEATER',
  BRIO_INAISLE_SFI_FENC: 'FENCING',
  dualpath_inaisle_sfi_generators: 'GENERATORS',
  dualpath_inaisle_sfi_windows: 'WINDOWS',
  'door-installation-dp/893e44520': 'DOORS',
  'dreamline-door-installation/44fbb1b75': 'DREAMLINE_SHOWER_DOOR',
  'plumbing-repair-dp/9f2b4389b': 'PLUMBING_REPAIR',
  'water-heater-repair-dp/201c992c0': 'WATER_HEATER_REPAIR',
  'cleancut-tub-installation-dp/28b4e734e': 'CLEANCUT_TUBS',
  BRIO_INAISLE_SFI_CLOS_ORG: 'CLOSETS',
  'countertop-installation-dp/6228e49a9': 'COUNTER_TOPS',
  'mini-split-installation-dp/723194ce9': 'MINI_SPLITS',
  'garage-door-opener-installation': 'GARAGE_DOOR',
  'bathroom-remodel-pip-dp/d9843b7cb': 'BATHROOM_REMODEL',
  'cleancut-tub-installation-pip-dp/28b4e734e': 'CLEANCUT_TUBS_PIP',
  'walk-in-tub-installation-dp/67a61682f': 'WALKIN_TUBS'
};

const dualPathTooltipCategories = {
  DEFAULT_SERVICE: [
    'Submit your contact information for a free pricing estimate and consultation',
    'Our expert installer will discuss the options available that best meet your needs and budget',
    'A licensed, local installer will schedule your installation and deliver the materials to your home',
    'Once the job is completed, we’ll clean up the area and request that you provide feedback on how we did'
  ],
  // Doors
  DOORS: [
    'Submit your contact information, and our project specialist will set up a professional measurement of your door opening',
    'A licensed, local installer will schedule and complete your measurement, and our project specialist will help select a door that meets your needs, budget and measurements',
    'Once your door arrives, our installer will schedule, deliver and complete the installation',
    'After the job is complete, we’ll clean up the area and request that you provide feedback on how we did'
  ],
  // tankless water heater
  TANKLESS_WATER_HEATER: [
    'Submit your contact information and our licensed, local installer will call to provide an estimate. If needed, they’ll schedule a free in-home consultation.',
    'During your install/consultation, the installer will assess your existing setup and provide a project quote.',
    'You can choose to have your new water heater installed right away or schedule a date that’s more convenient.',
    'The installer will test your new water heater and remove the old one. If required, they will pull a permit and schedule an inspection by the local municipality.'
  ],
  // water heater
  TANK_WATER_HEATER: [
    'Submit your contact information and our licensed, local installer will call to provide an estimate. If needed, they’ll schedule a free in-home consultation.',
    'During your install/consultation, the installer will assess your existing setup and provide a project quote.',
    'You can choose to have your new water heater installed right away or schedule a date that’s more convenient.',
    'The installer will test your new water heater and remove the old one. If required, they will pull a permit and schedule an inspection by the local municipality.'
  ],
  // fencing
  FENCING: [
    'Submit your contact information for a free pricing estimate and consultation',
    'Our local fencing installer will call to discuss your fencing needs and set up a free in-home consultation.',
    'During your free consultation, a licensed, local installer will measure your yard, review style options, and provide a project quote.',
    'When you’re ready to move forward, we’ll order the materials and schedule an installation and delivery date that’s convenient for you.',
    'After the installation is complete, the installer will haul away debris and clean the job site. They will also conduct a final inspection and review product and service warranties.'
  ],
  // generator
  GENERATORS: [
    'Submit your contact information for a free pricing estimate and consultation',
    'Our expert installer will discuss the options available that best meet your needs and budget and schedule your free in-home consultation.',
    'During your consultation, a licensed, local installer will review your home’s energy needs, make product recommendations, and review pricing and financing options.',
    'When you’re ready to move forward, the installer will order the material\'s and schedule an installation and delivery date that’s convenient for you.',
    'After the installation, the installer will clean the job site, make a final inspection, and review your product and service warranties.'
  ],
  WINDOWS: [
    'Submit your contact information for a free pricing estimate and consultation',
    'A Home Depot design consultant will call to discuss your current setup and the best options for your needs and budget. They will also set up a free in-home consultation',
    'During your consultation, we will help you choose the best windows for your needs, take professional measurements, and provide you with a project quote',
    'When you’re ready to move forward, we will order your windows and schedule your installation date',
    'After the installation is complete, we will haul away your old windows and clean up the job site',
    'Your new windows will come with a Home Depot Installation warranty and Manufacturers Product warranty'
  ],
  // dreamline door installtion
  DREAMLINE_SHOWER_DOOR: [
    'Submit your contact information for a free pricing estimate and consultation.',
    'Our local installer will call to discuss your needs and set up a free consultation.',
    'During your consultation, we’ll discuss your custom shower door project, make product recommendations, and review your pricing and financing options.',
    'When you’re ready to move forward, we’ll order all materials and set up an installation date that’s convenient for you.',
    'After the installation, we’ll clean the job site, make a final inspection, go over product and service warranties and request that you provide feedback on how we did.'
  ],
  // plumbing
  PLUMBING_REPAIR: [
    'Once you submit your contact info, one of our licensed, local, authorized plumbing service providers will contact you to discuss your project. They will also provide an estimate over the phone or schedule an in-home consultation to assess further.',
    'When you’re ready to move forward our plumber will schedule service when its most convenient for you.',
    'Once installation is complete, our plumber will test the repairs or installation, review the work with you, and clean up the job site.'
  ],
  // Garage-door opener installation
  GARAGE_DOOR: [
    'Enter your zip code to design and price your garage door',
    'Add installation and a garage door opener to complete the project',
    'After payment, our local installer will schedule a site inspection to confirm measurements',
    'When your garage door is ready, the installer will schedule your installation and deliver the materials to your home',
    'Once the job is completed, we’ll clean up the area and request that you provide feedback on how we did'
  ]
};

const headingCategory = {
  FENCING: 'Get Expert Fence Installation',
  TANKLESS_WATER_HEATER: 'Get Expert Water Heater Installation',
  TANK_WATER_HEATER: 'Get Expert Water Heater Installation',
  GENERATORS: 'Get Expert Generator Installation',
  WINDOWS: 'Get Expert Window Installation',
  DREAMLINE_SHOWER_DOOR: 'Get Expert Shower Door Installation',
  PLUMBING_REPAIR: 'Get Expert Plumbing Repair',
  WATER_HEATER_REPAIR: 'Get Expert Water Heater Repair',
  CLEANCUT_TUBS: 'Get Expert CleanCut Tub Installation',
  DOORS: 'Get Expert Door Installation',
  CLOSETS: 'Get Expert Closet Installation',
  COUNTER_TOPS: 'Get Expert Countertop Installation',
  MINI_SPLITS: 'Get Expert Mini Split Installation',
  GARAGE_DOOR: 'Get Expert Garage Door Installation',
  BATHROOM_REMODEL: 'Remodeling Your Bathroom?',
  DEFAULT_SERVICE: 'Get Expert In-Home Installation',
  CLEANCUT_TUBS_PIP: 'Get Expert In-Home Installation',
  WALKIN_TUBS: 'Get Expert Walk-in Tub Installation'
};

const checkboxText = {
  FENCING: 'Request a free consultation',
  TANKLESS_WATER_HEATER: 'Request a free consultation',
  TANK_WATER_HEATER: 'Request a free consultation',
  GENERATORS: 'Request a free consultation',
  WINDOWS: 'Request a consultation',
  DREAMLINE_SHOWER_DOOR: 'Request a consultation',
  PLUMBING_REPAIR: 'Request plumbing repair service',
  WATER_HEATER_REPAIR: 'Request water heater repair service',
  CLEANCUT_TUBS: 'Request a free consultation',
  DOORS: 'Request door installation service',
  CLOSETS: 'Request a free consultation',
  COUNTER_TOPS: 'Request a free consultation',
  MINI_SPLITS: 'Request a free consultation',
  GARAGE_DOOR: 'Customize your garage door with our online design tool and get a free quote',
  BATHROOM_REMODEL: 'Request a consultation',
  DEFAULT_SERVICE: 'Request installation service',
  CLEANCUT_TUBS_PIP: 'Request a consultation',
  WALKIN_TUBS: 'Request a consultation'
};

const buttonText = {
  FENCING: 'Request Free Consultation',
  TANKLESS_WATER_HEATER: 'Request Free Consultation',
  TANK_WATER_HEATER: 'Request Free Consultation',
  GENERATORS: 'Request Free Consultation',
  WINDOWS: 'Request Consultation',
  DREAMLINE_SHOWER_DOOR: 'Request Consultation',
  PLUMBING_REPAIR: 'Request Appointment',
  WATER_HEATER_REPAIR: 'Request Appointment',
  CLEANCUT_TUBS: 'Request Free Consultation',
  DOORS: 'Request Appointment',
  CLOSETS: 'Request Free Consultation',
  COUNTER_TOPS: 'Request Free Consultation',
  MINI_SPLITS: 'Request Free Consultation',
  GARAGE_DOOR: 'Get Started',
  BATHROOM_REMODEL: 'Request Consultation',
  DEFAULT_SERVICE: 'Request Appointment',
  CLEANCUT_TUBS_PIP: 'Request Consultation',
  WALKIN_TUBS: 'Request Consultation'
};

const getDualPathHeading = (category) => {
  const categoryKey = DUAL_PATH_CATEGORIES[category];
  if (headingCategory[categoryKey]) {
    return headingCategory[categoryKey];
  }
  return headingCategory.DEFAULT_SERVICE;
};

const getDualPathCheckbox = (category) => {
  const categoryKey = DUAL_PATH_CATEGORIES[category];
  if (checkboxText[categoryKey]) {
    return checkboxText[categoryKey];
  }
  return checkboxText.DEFAULT_SERVICE;
};

const getDualPathButtonText = (category) => {
  const categoryKey = DUAL_PATH_CATEGORIES[category];
  if (buttonText[categoryKey]) {
    return buttonText[categoryKey];
  }
  return buttonText.DEFAULT_SERVICE;
};

const getDualPathCategoryModel = (category) => {
  const categoryKey = DUAL_PATH_CATEGORIES[category];
  if (dualPathTooltipCategories[categoryKey]) {
    return dualPathTooltipCategories[categoryKey];
  }
  return dualPathTooltipCategories.DEFAULT_SERVICE;
};

export {
  getDualPathCategoryModel, getDualPathHeading, getDualPathButtonText, getDualPathCheckbox, DUAL_PATH_CATEGORIES
};