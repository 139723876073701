/* eslint-disable max-len */
export const WIDGET_MAPPING = {
  PIP: {
    header: 'Make Sure This Refrigerator Fits',
    subtext: 'Learn how to measure for easy installation',
    canHaveArrow: false,
    isPip: true
  },
  PLP: {
    header: 'Find Refrigerators That Fit Your Home',
    subtext: 'Measure your space to help you narrow down what works.',
    canHaveArrow: true,
    isPlp: true
  },
  MINI: {
    header: 'Make sure it will fit',
    isMini: true
  }
};
export const TYPE_LIST = [...Object.keys(WIDGET_MAPPING)];

export const ANIMATIONS = {
  DELIVERY_PATH: 'https://dam.thdstatic.com/content/production/XB_IEIRYwATswFPkUxKwgA/F_UW0OcaF8A47YFOoIGvEQ/Original%20file/d29a-major-appliance-delivery-ux---refrigerator-delivery-path---500px-0824.gif',
  REFRIGERATOR_MEASURE_WIDTH: 'https://dam.thdstatic.com/content/production/rEQpLV7muxt2wVAEGWHCBQ/anC0JuGFBzxJ8Cn-_FK35g/Original%20file/d29a-major-appliance-delivery-ux---refrigerator-measure-width---500px-0724.gif',
  REFRIGERATOR_MEASURE_HEIGHT: 'https://dam.thdstatic.com/content/production/2dmhK03g_srKintsykd5nw/3XJW5XvfKo6bc8akdthW8w/Original%20file/d29a-major-appliance-delivery-ux---refrigerator-measure-height---500px-0824.gif',
  REFRIGERATOR_MEASURE_DEPTH: 'https://dam.thdstatic.com/content/production/yqN0_mtdwvV_fwZKusNtJw/oZJplEVCKVsj4nbn76OtDA/Original%20file/d29a-major-appliance-delivery-ux---refrigerator-measure-depth---500px-0724.gif',
  REFRIGERATOR_DOOR_CHECK: 'https://dam.thdstatic.com/content/production/W80wzNV4gKJhwJyKoy_A5Q/uoQglKkckmdyMqd_VZlyBA/Original%20file/d29a-major-appliance-delivery-ux---refrigerator-door-check---500px-0824.gif'
};

export const IMAGES = {
  REFRIGERATOR_STANDARD_DEPTH: 'https://dam.thdstatic.com/content/production/2f9oz_QDxF--uDl7LPpKVQ/CSbZHaYnTK79SHaEAHaKJA/Original%20file/d29a-major-appliance-delivery-ux--refrigerator-standard-depth---500px-0824.png',
  REFRIGERATOR_COUNTER_DEPTH: 'https://dam.thdstatic.com/content/production/jiONqGsSsPuwQurWePx6JQ/I_RV2C5QYQ5ErlJ7M7m_Xg/Original%20file/d29a-major-appliance-delivery-ux--refrigerator-counter-depth---500px-0824.png'
};

export const DRAWER_MAPPING = [
  // Page 1
  {
    header: 'Make Sure It Fits',
    sections: [
      {
        text: {
          string: 'Let\'s Measure Your Space In Four Easy Steps',
          isTitle: true
        }
      },
      {
        text: {
          string: 'Accurate measurements will ensure a perfect fit for your new refrigerator. You\'ll need a tape measure and be ready to take notes.'
        }
      },
      {
        tip: {
          string: 'You\'re not measuring your old refrigerator. Focus on the space it will be installed and clear a path for delivery.'
        },
        modifiers: {
          hasBorder: true
        }
      },
      {
        text: {
          string: 'Need help measuring? Please call your local store for more information.'
        }
      }
    ],
    footer: 'Next'
  },
  // Page 2
  {
    header: 'Step 1 of 4',
    sections: [
      {
        text: {
          string: 'Map Your Delivery Path',
          isTitle: true
        }
      },
      {
        text: {
          string: 'We want delivery and installation to go easy for you.'
        }
      },
      {
        text: {
          string: 'Our experts suggest:'
        },
        modifiers: {
          marginOverride: 2
        }
      },
      {
        list: [
          'Find a route with the least obstacles from the delivery truck to the installation location.',
          'Measure your front door, interior doors, hallways and tight spaces along this path.',
          'Take note of the narrowest gap and tight turns.'
        ]
      },
      {
        image: {
          src: ANIMATIONS.DELIVERY_PATH,
          alt: 'Delivery Path',
          size: '100%'
        }
      },
      {
        text: {
          string: 'This will help you know if your refrigerator will clear the delivery path.'
        }
      },
      {
        tip: {
          string: 'If you\'re refrigerator is a few inches too big for delivery, we can remove the the handles for clearance.'
        },
        modifiers: {
          hasBorder: true
        }
      }
    ],
    footer: 'Next'
  },
  // Page 3
  {
    header: 'Step 2 of 4',
    sections: [
      {
        text: {
          string: 'Measure the Width',
          isTitle: true
        }
      },
      {
        text: {
          string: 'In the space where your refrigerator will go, take note of the width in three places:'
        },
        modifiers: {
          marginOverride: 2
        }
      },
      {
        list: [
          'the upper cabinet',
          'the counter top',
          'the base levels'
        ],
      },
      {
        text: {
          string: 'Use the smallest measurement of the three.'
        }
      },
      {
        image: {
          src: ANIMATIONS.REFRIGERATOR_MEASURE_WIDTH,
          alt: 'Refrigerator Measure Width',
          size: '100%'
        }
      },
      {
        tip: {
          string: 'Leave a minimum of 1/8-inch on both sides of the refrigerator for ventilation. If your refrigerator is located next to a wall, leave 4 1/2-inch between the wall and the hinge side of the door so it can swing open.'
        },
        modifiers: {
          hasBorder: true
        }
      },
      {
        text: {
          string: 'Standard refrigerators are 28 3/4-inches - 39 3/4 inches wide.',
          isTiny: true
        }
      }
    ],
    footer: 'Next'
  },
  // Page 4
  {
    header: 'Step 3 of 4',
    sections: [
      {
        text: {
          string: 'Measure the Height',
          isTitle: true
        }
      },
      {
        text: {
          string: 'Measure from the floor to the lowest point of the top of  your fridge space. Do this in both the front and back of the space.'
        }
      },
      {
        text: {
          string: 'Use the shorter of these two measurements for height.'
        },
        modifiers: {
          marginOverride: 6
        }
      },
      {
        image: {
          src: ANIMATIONS.REFRIGERATOR_MEASURE_HEIGHT,
          alt: 'Refrigerator Measure Height',
          size: '100%'
        }
      },
      {
        tip: {
          string: 'Leave a minimum of 1-inch gap from the top to leave space. Why?',
          sections: [
            {
              list: [
                'Make room for the refrigerator door hinge to operate freely',
                'Proper ventilation improves performance and efficiency'
              ],
              modifiers: {
                marginOverride: 0
              }
            }
          ]
        },
        modifiers: {
          hasBorder: true
        }
      },
      {
        text: {
          string: 'Standard refrigerators are 61 3/4-inches - 71 3/4 inches high.',
          isTiny: true
        }
      }
    ],
    footer: 'Next'
  },
  // Page 5
  {
    header: 'Step 4 of 4',
    sections: [
      {
        text: {
          string: 'Decide on Depth',
          isTitle: true
        }
      },
      {
        text: {
          string: 'Measure the depth from the back wall to the front edge of your counters.'
        }
      },
      {
        image: {
          src: ANIMATIONS.REFRIGERATOR_MEASURE_DEPTH,
          alt: 'Refrigerator Measure Depth',
          size: '100%'
        }
      },
      {
        tip: {
          string: 'Leave a minimum of 2-inches from the back wall for ventilation.'
        },
        modifiers: {
          hasBorder: true
        }
      },
      {
        text: {
          string: 'You have two options - standard-depth and counter-depth refrigerators.'
        },
        modifiers: {
          marginOverride: 2
        }
      },
      [
        [
          {
            list: [
              'Standard-depth refrigerators extend about 6-inches past the front of the counters.'
            ]
          },
          {
            text: {
              string: 'Standard refrigerators are 28 3/4-inches - 34 5/8-inches deep.',
              isTiny: true
            },
            modifiers: {
              shiftLeft: true,
              marginOverride: 4
            }
          }
        ],
        [
          {
            list: [
              'Counter-depth refrigerators will fit flush with your kitchen counter tops.'
            ]
          },
          {
            text: {
              string: 'Standard counter-depth refrigerators are 24 inches 30 inches deep.',
              isTiny: true
            },
            modifiers: {
              shiftLeft: true,
              marginOverride: 4
            }
          },
        ]
      ],
      {
        sections: [
          {
            image: {
              src: IMAGES.REFRIGERATOR_STANDARD_DEPTH,
              alt: 'Refrigerator Standard Depth',
              size: '100%',
              subHeader: 'Standard Depth'
            },
            modifiers: {
              hasBorder: true
            }
          },
          {
            image: {
              src: IMAGES.REFRIGERATOR_COUNTER_DEPTH,
              alt: 'Refrigerator Counter Depth',
              size: '100%',
              subHeader: 'Counter Depth'
            },
            modifiers: {
              hasBorder: true
            }
          }
        ],
        modifiers: {
          marginOverride: 8,
          flexGroup: true
        }
      },
      {
        text: {
          string: 'Make sure there\'s enough space in front of the fridge for kitchen cabinets and drawers to fully open.'
        }
      },
      {
        image: {
          src: ANIMATIONS.REFRIGERATOR_DOOR_CHECK,
          alt: 'Refrigerator Door Check',
          size: '100%'
        },
        modifiers: {
          marginOverride: 0
        }
      }
    ],
    footer: 'Done'
  }
];
