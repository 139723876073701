import React, {
  useContext, useRef, useState, useMemo, useEffect
} from 'react';
import {
  arrayOf, bool, func, number, shape, string, any, object
} from 'prop-types';
import { ExperienceContext, useConfigService } from '@thd-nucleus/experience-context';
import { useLifeCycleEventBus } from '@thd-olt-functional/utils';
import { InstantCheckout } from '@thd-olt-component-react/instant-checkout';
import { StickyWithHeaderObserver } from '@thd-olt-component-react/sticky';
import { Quantity } from '@thd-olt-component-react/quantity';
import classNames from 'classnames/bind';
import {
  DetaCountDownTimer,
  getMaxDeliverableQty
} from '@thd-olt-component-react/fulfillment';
import { AddToList } from '@thd-olt-component-react/add-to-list';
import { PaypalCheckoutButton } from '@thd-olt-component-react/paypal-checkout-button';
import { useTheme } from '@thd-olt-component-react/theme-provider';
import { Alert, Typography } from '@one-thd/sui-atomic-components';
import { extend } from '@thd-nucleus/data-sources';
import { BuyboxAddToCart } from './BuyboxAddToCart';
import { BuyboxAddToList } from './BuyboxAddToList';
import { BuyboxAddToQuote } from './BuyboxAddToQuote';
import { Paypal } from './Paypal';
import {
  getCartOptions,
  getCartReqParams,
  shouldDisplaySubbuybox,
  getFulfillmentLocation,
  buildAddToCartRequest,
} from '../../BuyboxHelper';
import { useBogoInfo } from '../hooks/useBogoInfo';
import '../buybox.scss';
import { formatDate, isMobile, getDiscount } from '../../util';
import { FULFILLMENT_METHOD_BOPIS, FULFILLMENT_METHOD_STH } from '../../constants';
import { useDebouncedQuantity } from '../hooks/useDebouncedQuantity';

const BuyboxActions = ({
  alterBrowserHistory,
  disableATC,
  fulfillment,
  fulfillmentMethod,
  hideInstantCheckout,
  hideQuantity,
  isAssembleItemAtStore,
  itemId,
  configurableItems,
  configurableProductDetails: {
    isSpecialOrder,
    configuratorData,
    disableTimer,
  },
  onClickPassThrough,
  minimumOrderQuantity,
  onATCClick,
  onQuantityChange,
  paypalEligible,
  productAddOns = {},
  quantity,
  originalConfigId,
  quantityLimitExceed,
  hidePaypalCheckout,
  sticky,
  storeId,
  storeZip,
  zipCode,
  showCards,
  showFavoriteAndQuote,
  isTwoTileEnabledFS,
  maxQuantityExceeded = false,
  quantityLimit,
  fallbackMode = false,
  customATC,
  itemPrice,
  minimumBopisQuantity,
  maximumBopisQuantity,
  bulk,
  bipCartItems,
  conditionalPromotions
}) => {

  const theme = useTheme(BuyboxActions);
  const { hideAddToQuoteButton, hidePaypal } = theme.props;
  const { channel, isConsumerApp } = useContext(ExperienceContext);
  const buyboxActionsRef = useRef(null);
  const bogoInfo = useBogoInfo();
  const isBogoSelected = bogoInfo?.bogoSelectedProduct?.itemId;
  const isSubscribeAndSaveOpted = !!productAddOns?.subscriptions?.frequency;
  const buyboxActionClasses = classNames('buybox__actions', {
    'buybox__actions--margin-bottom': isBogoSelected
  });

  const isFallbackApplicable = fallbackMode && fulfillmentMethod !== FULFILLMENT_METHOD_BOPIS;

  const get2TileQuantityLimit = isFallbackApplicable ? 0 : quantityLimit;
  const isPaypalPayInFour = useConfigService('paypalPayInFour');
  const showPaypalSmartButton = useConfigService('PAYPAL_ENABLE');

  const configuratorCart = configuratorData?.product?.configuration?.cart;

  const bmsmPromotion = useMemo(() => {
    if (conditionalPromotions) {
      return conditionalPromotions.find((promotion) => {
        return promotion.experienceTag === 'BMSM' || promotion.experienceTag === 'MSBONLINE';
      });
    }
    return false;
  }, [conditionalPromotions]);

  const bogoPromotion = useMemo(() => {
    if (conditionalPromotions) {
      return conditionalPromotions.find((promotion) => {
        return promotion.experienceTag === 'BOGO';
      });
    }
    return false;
  }, [conditionalPromotions]);

  const { isHDPP, HDPPValue } = useLifeCycleEventBus('HDPP.change');
  const { isOptedIn, attachLaborValue } = useLifeCycleEventBus('attach-labor-paypal.change');
  const [paypalInitError, setPaypalInitError] = useState(false);
  const [paypalCartError, setPaypalCartError] = useState(false);

  useEffect(() => {
    LIFE_CYCLE_EVENT_BUS.on('paypal-checkout-button.initError', () => {
      setPaypalInitError(true);
    });

    LIFE_CYCLE_EVENT_BUS.on('paypal-checkout-button.addToCartError', () => {
      setPaypalCartError(true);
    });
  }, []);

  const minimumQuantity = Math.max(minimumOrderQuantity || 1, minimumBopisQuantity);
  const limit = maximumBopisQuantity !== null
    ? Math.min(...([get2TileQuantityLimit, maximumBopisQuantity].filter((lim) => lim > 0)))
    : get2TileQuantityLimit;

  const quantityNode = !hideQuantity && (
    <div className="buybox__quantity--wrapper">
      <Quantity
        className={quantityLimitExceed ? 'buybox__quantity--warning' : ''}
        minimumQuantity={minimumQuantity}
        onChange={onQuantityChange}
        value={quantity}
        showWarningState={isFallbackApplicable ? false : maxQuantityExceeded}
        quantityLimit={isTwoTileEnabledFS ? limit : 0}
        decrementDisabled={minimumQuantity > 1 && quantity <= minimumQuantity}
        incrementDisabled={limit > 0 && quantity >= limit}
      />
    </div>
  );

  const addToCartNode = (
    <BuyboxAddToCart
      alterBrowserHistory={alterBrowserHistory}
      disableATC={isFallbackApplicable
        ? false : disableATC || ((limit > 0 && quantity > limit) || quantity < minimumQuantity)}
      fulfillment={fulfillment}
      fulfillmentMethod={fulfillmentMethod}
      isAssembleItemAtStore={isAssembleItemAtStore}
      itemId={itemId}
      originalConfigId={originalConfigId}
      configurableItems={configurableItems}
      onClickPassThrough={onClickPassThrough}
      onATCClick={onATCClick}
      configuratorCart={configuratorCart}
      productAddOns={productAddOns}
      quantity={quantity}
      quantityLimitExceed={isFallbackApplicable ? false : quantityLimitExceed}
      storeId={storeId}
      storeZip={storeZip}
      zipCode={zipCode}
      showFavoriteAndQuote={showFavoriteAndQuote}
      isSpecialOrder={isSpecialOrder}
      customATC={customATC}
    />
  );

  const deliveryService = (fulfillment?.fulfillmentOptions || []).find((option) => option.type === 'delivery');
  const sthFulfillment = (deliveryService?.services || []).find((service) => service.type === 'sth');
  const sthLocation = (sthFulfillment?.locations || []).find((location) => location?.type === 'online');
  const sthInventory = sthLocation?.inventory?.quantity || 0;

  const bodfsFulfillment = (deliveryService?.services || []).find((service) => service.type === 'express delivery');
  const bodfsLocation = (bodfsFulfillment?.locations || []).find((location) => location?.type === 'store');

  const [showCountdownTime, changeCountdownTimeStatus] = useState(true);
  const removeTimer = () => changeCountdownTimeStatus(false);
  const showDetaTimer = isTwoTileEnabledFS
    && !disableTimer
    && fulfillmentMethod === FULFILLMENT_METHOD_STH;

  const showInstantCheckout = () => {

    const { attachedLabor = [], service = {}, subscriptions = {}, dualPath } = productAddOns || {};

    if (fulfillmentMethod !== FULFILLMENT_METHOD_STH) {
      return false;
    }

    if (quantity > sthInventory) {
      return false;
    }

    if ((attachedLabor.length && attachedLabor[0].attachedLaborSku)
      || (service?.type && service?.category === 'assembly')
      || subscriptions.frequency
      || dualPath) {
      return false;
    }

    if (disableATC || quantityLimitExceed || hideInstantCheckout) {
      return false;
    }

    return true;
  };

  const stickyClassnames = classNames('buybox__actions', {
    'buybox__actions--sticky': !isConsumerApp && !showFavoriteAndQuote,
    'buybox__actions--sticky-app': isConsumerApp && !showFavoriteAndQuote,
    'buybox__actions--sticky-favorites': !isConsumerApp && showFavoriteAndQuote
  });

  const renderPaypalSmartButton = showPaypalSmartButton
    && shouldDisplaySubbuybox({
      fulfillment,
      fulfillmentMethod
    })
    && paypalEligible
    && !hidePaypal
    && !hidePaypalCheckout
    && !isBogoSelected
    && !isSubscribeAndSaveOpted
    && !paypalInitError;
  const debouncedQuantity = useDebouncedQuantity(quantity, renderPaypalSmartButton);
  let orderTotal = debouncedQuantity * itemPrice;
  const discount = getDiscount(bmsmPromotion, debouncedQuantity, orderTotal);
  if (isHDPP) { orderTotal += HDPPValue; }
  if (isOptedIn) { orderTotal += attachLaborValue; }
  orderTotal -= (orderTotal * (discount / 100));
  let bulkTotal = 0;

  if (debouncedQuantity >= bulk?.thresholdQuantity) {
    bulkTotal = bulk?.value * debouncedQuantity;
    if (isHDPP) { bulkTotal += HDPPValue; }
    if (isOptedIn) { bulkTotal += attachLaborValue; }
  }

  const customPaypalHandler = useMemo(() => {
    if (renderPaypalSmartButton && isSpecialOrder && customATC) {
      const cartOptions = { storeId, fulfillmentMethod };

      return async () => {
        const data = await customATC(cartOptions, true);

        if (data) {
          const cartReq = buildAddToCartRequest({
            itemId: data.itemId,
            quantity: data.quantity,
            fulfillmentMethod: data.fulfillmentMethod,
            configId: data.configurationId,
            productAddOns,
            storeZip,
            storeId,
            zipCode,
            isAssembleItemAtStore
          });
          return cartReq;
        }
        return null;
      };
    }
    return null;
  }, [
    fulfillmentMethod,
    storeId,
    storeZip,
    zipCode,
    productAddOns,
    isAssembleItemAtStore,
  ]);

  const getTotalPaypalMessaging = () => {
    if (bogoPromotion) return null;
    if (bulkTotal) return bulkTotal.toString();
    return orderTotal.toString();
  };

  const cartRequest = useMemo(() => {
    if (renderPaypalSmartButton && !isSpecialOrder) {
      return buildAddToCartRequest({
        itemId,
        quantity: debouncedQuantity,
        originalConfigId,
        fulfillmentMethod,
        configurableItems,
        productAddOns,
        storeZip,
        storeId,
        zipCode,
        isAssembleItemAtStore,
        bipCartItems,
        configuratorCart
      });
    }
    return null;
  }
  , [itemId,
    debouncedQuantity,
    fulfillmentMethod,
    JSON.stringify(configurableItems),
    productAddOns?.warrantyItem?.itemId,
    productAddOns?.attachedLabor?.[0]?.attachedLaborSku,
    productAddOns?.service?.type,
    storeZip,
    storeId,
    zipCode,
    isAssembleItemAtStore,
    isPaypalPayInFour,
    configuratorCart,
    bipCartItems]);

  return (
    <>
      {sticky && isMobile(channel) && (
        <StickyWithHeaderObserver
          boxShadow
          className={stickyClassnames}
          childRef={buyboxActionsRef}
          forwardRef={buyboxActionsRef}
          offsetScroll={18}
          position="bottom"
          stickyByDefault
          stickyType="forwardRef"
          visibleByDefault
        >
          {!showFavoriteAndQuote ? (
            <div className="buybox__actions--flex">
              {quantityNode}
              {addToCartNode}
            </div>
          ) : (
            <div>
              <div className="buybox__actions--flex">
                {quantityNode}
                {addToCartNode}
              </div>
              <div className={!hideAddToQuoteButton ? 'buybox__actions--atl-atq' : 'sui-mt-2'}>
                <BuyboxAddToList
                  quantity={quantity}
                  itemId={itemId}
                  isStickyChild
                />
                {
                  !hideAddToQuoteButton
                  && (
                    <BuyboxAddToQuote
                      itemId={itemId}
                      fulfillmentLocation={
                        getFulfillmentLocation(fulfillment, fulfillmentMethod, storeZip, storeId, zipCode)
                      }
                      fulfillmentMethod={fulfillmentMethod}
                      quantity={quantity}
                      disabled={disableATC || quantityLimitExceed}
                    />
                  )
                }
              </div>
            </div>
          )}
        </StickyWithHeaderObserver>
      )}
      {!showFavoriteAndQuote ? (
        <div className={buyboxActionClasses}>
          {!fallbackMode && showDetaTimer && (
            <DetaCountDownTimer
              dynamicEtaMinutes={sthFulfillment?.dynamicEta?.minutes}
              dynamicEtaHours={sthFulfillment?.dynamicEta?.hours}
              showCountdownTime={showCountdownTime}
              inventoryQuantity={getMaxDeliverableQty({ sthLocation, bodfsLocation })}
              removeTimer={removeTimer}
              deliveryStartDate={sthFulfillment?.deliveryDates?.startDate}
              isLongDayName={false}
              deliveryTimeline={sthFulfillment?.deliveryTimeline}
              startDate={formatDate(sthFulfillment?.deliveryDates?.startDate, true)}
              zipCode={zipCode}
            />
          )}
          { (paypalInitError || paypalCartError)
            && (
              <Alert status="error">
                { paypalInitError
                  ? (
                    <Typography variant="body-base">
                      Oops! Something went wrong. You can checkout with PayPal from Cart or Checkout.
                    </Typography>
                  )
                  : (
                    <Typography variant="body-base">
                      We&apos;re sorry, something went wrong. Please try again.
                    </Typography>
                  )}
              </Alert>
            )}
          <div className="buybox__actions--flex">
            {quantityNode}
            <div className="buybox__actions--atc">
              <div ref={buyboxActionsRef}>
                {addToCartNode}
              </div>
              {showInstantCheckout() && (
                <div data-testid="instant-checkout-mock" style={showCards ? {} : { minHeight: '80px' }}>
                  <InstantCheckout
                    fulfillmentMethod={fulfillmentMethod}
                    itemId={itemId}
                    quantity={quantity}
                    productAddOns={productAddOns}
                    hideInvitation={showCards}
                  />
                </div>
              )}
              {shouldDisplaySubbuybox({
                fulfillment,
                fulfillmentMethod
              }) && paypalEligible && !hidePaypal && !hidePaypalCheckout
              && !isBogoSelected && !showPaypalSmartButton && (
                <Paypal
                  cartOptions={getCartOptions({
                    channel,
                    configurableItems,
                    fulfillmentMethod,
                    storeId,
                    storeZip,
                    zipCode,
                    isConfigurableProduct: !!configuratorCart
                  })}
                  cartReqParams={getCartReqParams({
                    itemId,
                    configurableItems,
                    fulfillment,
                    fulfillmentMethod,
                    productAddOns,
                    quantity,
                    originalConfigId,
                    storeId,
                    storeZip,
                    zipCode
                  })}
                  channel={channel}
                  disabled={disableATC || quantityLimitExceed}
                  customATC={customATC && isSpecialOrder ? customATC : null}
                />
              )}
              {renderPaypalSmartButton
                && !(isMobile(channel))
                && (
                  <div className="sui-mt-2">
                    <PaypalCheckoutButton
                      payInFour={isPaypalPayInFour}
                      orderTotal={getTotalPaypalMessaging()}
                      isValidCart
                      cartRequest={cartRequest}
                      itemCount={quantity.toString()}
                      fromPage="pip"
                      ffm={fulfillmentMethod}
                      itemPrice={itemPrice}
                      hideButton={bmsmPromotion || bogoPromotion}
                      disabled={disableATC || quantityLimitExceed}
                      customPaypalHandler={customPaypalHandler}
                    />
                  </div>
                )}
            </div>
          </div>
          {renderPaypalSmartButton
            && (isMobile(channel))
            && (
              <div className="sui-mt-2">
                <PaypalCheckoutButton
                  payInFour={isPaypalPayInFour}
                  orderTotal={getTotalPaypalMessaging()}
                  isValidCart
                  cartRequest={cartRequest}
                  itemCount={quantity.toString()}
                  fromPage="pip"
                  ffm={fulfillmentMethod}
                  itemPrice={itemPrice}
                  hideButton={bmsmPromotion || bogoPromotion}
                  disabled={disableATC || quantityLimitExceed}
                  customPaypalHandler={customPaypalHandler}
                />
              </div>
            )}
        </div>
      ) : (
        <div className={buyboxActionClasses}>
          {!fallbackMode && showDetaTimer && (
            <DetaCountDownTimer
              dynamicEtaMinutes={sthFulfillment?.dynamicEta?.minutes}
              dynamicEtaHours={sthFulfillment?.dynamicEta?.hours}
              showCountdownTime={showCountdownTime}
              inventoryQuantity={getMaxDeliverableQty({ sthLocation, bodfsLocation })}
              removeTimer={removeTimer}
              deliveryStartDate={sthFulfillment?.deliveryDates?.startDate}
              isLongDayName={false}
              deliveryTimeline={sthFulfillment?.deliveryTimeline}
              startDate={formatDate(sthFulfillment?.deliveryDates?.startDate, true)}
              zipCode={zipCode}
            />
          )}
          <div className="buybox__actions--flex">
            {quantityNode}
            {addToCartNode}
          </div>
          <div className="buybox__actions">
            <div className={!hideAddToQuoteButton ? 'buybox__actions--atl-atq' : 'sui-mt-2'} ref={buyboxActionsRef}>
              <BuyboxAddToList
                quantity={quantity}
                itemId={itemId}
                isStickyChild={false}
              />
              {
                !hideAddToQuoteButton
                && (
                  <BuyboxAddToQuote
                    itemId={itemId}
                    fulfillmentLocation={
                      getFulfillmentLocation(
                        fulfillment,
                        fulfillmentMethod,
                        storeZip,
                        storeId,
                        zipCode
                      )
                    }
                    fulfillmentMethod={fulfillmentMethod}
                    quantity={quantity}
                    disabled={disableATC || quantityLimitExceed}
                  />
                )
              }
            </div>
            {(!disableATC && !quantityLimitExceed)
            && !hideInstantCheckout && (
              <div style={showCards ? {} : { minHeight: '80px' }}>
                <InstantCheckout
                  fulfillmentMethod={fulfillmentMethod}
                  itemId={itemId}
                  quantity={quantity}
                  productAddOns={productAddOns}
                  hideInvitation={showCards}
                />
              </div>
            )}
            {shouldDisplaySubbuybox({
              fulfillment,
              fulfillmentMethod
            }) && paypalEligible && !hidePaypal && !hidePaypalCheckout
            && !isBogoSelected && !showPaypalSmartButton && (
              <Paypal
                cartOptions={getCartOptions({
                  channel,
                  configurableItems,
                  fulfillmentMethod,
                  storeId,
                  storeZip,
                  zipCode,
                  isConfigurableProduct: !!configuratorCart
                })}
                cartReqParams={getCartReqParams({
                  itemId,
                  configurableItems,
                  fulfillment,
                  fulfillmentMethod,
                  productAddOns,
                  quantity,
                  originalConfigId,
                  storeId,
                  storeZip,
                  zipCode
                })}
                channel={channel}
                disabled={disableATC || quantityLimitExceed}
              />
            )}
            {renderPaypalSmartButton
                && !(isMobile(channel))
                && (
                  <div className="sui-mt-2">
                    <PaypalCheckoutButton
                      payInFour={isPaypalPayInFour}
                      orderTotal={getTotalPaypalMessaging()}
                      isValidCart
                      cartRequest={cartRequest}
                      itemCount={quantity}
                      fromPage="pip"
                      ffm={fulfillmentMethod}
                      itemPrice={itemPrice}
                      hideButton={bmsmPromotion || bogoPromotion}
                      disabled={disableATC || quantityLimitExceed}
                      customPaypalHandler={customPaypalHandler}
                    />
                  </div>
                )}
          </div>
        </div>
      )}
    </>
  );
};

BuyboxActions.displayName = 'BuyboxActions';

BuyboxActions.themeProps = {
  hideAddToQuoteButton: bool,
  hidePaypal: bool
};

BuyboxActions.defaultThemeProps = {
  hideAddToQuoteButton: false,
  hidePaypal: false
};

BuyboxActions.dataModel = extend(AddToList);

BuyboxActions.propTypes = {
  alterBrowserHistory: bool,
  disableATC: bool,
  fulfillment: shape({
    type: string,
    locations: arrayOf(shape({
      isAnchor: bool,
      inventory: shape({
        quantity: number,
        isInStock: bool,
        isLimitedQuantity: bool,
        isBackordered: bool
      })
    }))
  }),
  onClickPassThrough: func,
  fulfillmentMethod: string,
  hideInstantCheckout: bool,
  hideQuantity: bool,
  isAssembleItemAtStore: bool,
  itemId: string.isRequired,
  configurableItems: arrayOf(
    shape({
      itemId: string,
      isAnchor: bool,
    })
  ),
  configurableProductDetails: shape({
    isSpecialOrder: bool,
    configuratorHideQuantity: bool,
    currentSelectedFulfillment: string,
    /* eslint-disable-next-line react/forbid-prop-types */
    configuratorData: object,
    isTwoTileEnabledFS: bool,
    disableTimer: bool
  }),
  minimumOrderQuantity: number,
  onATCClick: func,
  onQuantityChange: func,
  productAddOns: shape({
    attachedLabor: arrayOf(shape({
      attachedLaborSku: string
    })),
    warrantyItem: shape({
      itemId: string
    })
  }),
  paypalEligible: bool,
  quantity: number,
  quantityLimitExceed: bool,
  hidePaypalCheckout: bool,
  sticky: bool,
  storeId: string,
  storeZip: string,
  zipCode: string,
  showCards: bool,
  showFavoriteAndQuote: bool,
  isTwoTileEnabledFS: bool,
  maxQuantityExceeded: bool,
  quantityLimit: number,
  fallbackMode: bool,
  customATC: func,
  itemPrice: number,
  minimumBopisQuantity: number,
  maximumBopisQuantity: number,
  originalConfigId: string,
  bulk: shape({
    thresholdQuantity: number,
    value: number,
  }),
  bipCartItems: shape({
    pickup: arrayOf(shape({})),
    delivery: arrayOf(shape({
      itemId: string,
      quantity: string,
      type: string
    }))
  }),
  conditionalPromotions: arrayOf(shape({
    experienceTag: string,
    reward: shape({
      tiers: arrayOf(shape({
        minPurchaseQuantity: number,
        minPurchaseAmount: number,
        rewardPercent: number,
      }))
    })
  })),
};

BuyboxActions.defaultProps = {
  alterBrowserHistory: false,
  configurableItems: null,
  onClickPassThrough: null,
  disableATC: false,
  fulfillment: null,
  fulfillmentMethod: '',
  hideInstantCheckout: false,
  hideQuantity: false,
  isAssembleItemAtStore: null,
  minimumOrderQuantity: 1,
  onATCClick: () => {},
  onQuantityChange: () => {},
  paypalEligible: false,
  productAddOns: null,
  quantity: 1,
  quantityLimitExceed: false,
  hidePaypalCheckout: false,
  sticky: false,
  storeId: null,
  storeZip: null,
  zipCode: null,
  showCards: null,
  showFavoriteAndQuote: false,
  maxQuantityExceeded: false,
  quantityLimit: 0,
  fallbackMode: false,
  isTwoTileEnabledFS: true,
  configurableProductDetails: {
    isSpecialOrder: false,
    configuratorHideQuantity: false,
    currentSelectedFulfillment: '',
    isTwoTileEnabledFS: true,
    disableTimer: false
  },
  customATC: null,
  itemPrice: 0,
  minimumBopisQuantity: null,
  maximumBopisQuantity: null,
  originalConfigId: null,
  bulk: null,
  bipCartItems: null,
  conditionalPromotions: null,
};

export { BuyboxActions };
