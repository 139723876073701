import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Modal, ModalHeader, ModalBody } from '@one-thd/sui-atomic-components';
import { InputForm } from './subcomponents/inputForm/InputForm';
import {
  DELIVERY_ZIP_BODY,
  DELIVERY_ZIP_HEADER,
  DELIVERY_ZIP_INPUT
} from '../helpers/constants';

export const ZipInputModal = ({ onSubmit, errorMessage, overlay, setOverlay }) => (
  <div data-testid="DesktopModal">
    <Modal open={overlay} size="sm">
      <ModalHeader onClose={() => setOverlay(false)}>{DELIVERY_ZIP_HEADER}</ModalHeader>
      <ModalBody>
        <Typography> {DELIVERY_ZIP_BODY}</Typography>
        <div className="sui-pt-6">
          <InputForm onSubmit={onSubmit} errorMessage={errorMessage} placeholderText={DELIVERY_ZIP_INPUT} />
        </div>
      </ModalBody>
    </Modal>
  </div>
);

ZipInputModal.propTypes = {
  onSubmit: PropTypes.func,
  errorMessage: PropTypes.string,
  overlay: PropTypes.bool,
  setOverlay: PropTypes.func,
};

ZipInputModal.defaultProps = {
  onSubmit: () => {},
  errorMessage: null,
  overlay: null,
  setOverlay: () => {},
};
