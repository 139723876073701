export const formatDate = (date, isLongMonthName) => {
  if (!date) {
    return date;
  }
  const dateparts = date.split('-');
  const formattedDate = new Date(dateparts[0], dateparts[1] - 1, dateparts[2], 0, 0, 0);
  const longMonthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ];
  const shortMonthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  if (isLongMonthName) {
    return longMonthNames[formattedDate.getMonth()] + ' ' + formattedDate.getDate();
  }
  return shortMonthNames[formattedDate.getMonth()] + ' ' + formattedDate.getDate();
};

export const formatWeek = (date, isLongDayName) => {
  if (!date) {
    return date;
  }
  const dateparts = date.split('-');
  const formattedDate = new Date(dateparts[0], dateparts[1] - 1, dateparts[2], 0, 0, 0);
  const longWeekNames = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday'
  ];
  const shortWeekNames = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  if (isLongDayName) {
    return longWeekNames[formattedDate.getDay()];
  }
  return shortWeekNames[formattedDate.getDay()];
};

export const isMobile = (channel) => {
  return channel?.toLowerCase() === 'mobile';
};

export const isSthAndInStock = ({ availableFulfillments, isOutOfStockOnline }) => {
  return availableFulfillments.indexOf('ShipToHome') > -1 && !isOutOfStockOnline;
};

export const getDeliveryZip = () => {
  let zipCode = '';
  try {
    if (typeof window !== 'undefined') {
      const zipDetails = window?.THD_LOCALIZER_AUTO_INIT?.Localizer?.getDeliveryZipcodeDetails();
      zipCode = zipDetails?.zipcode;
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
  return zipCode;
};

export const checkIfUserIsInStore = () => (
  new Promise((resolve, reject) => {
    try {
      if (typeof window !== 'undefined') {
        window.navigator.geolocation.getCurrentPosition((location) => {
          THD_LOCALIZER_AUTO_INIT.Localizer.checkForStoreNumberForUserInsideStore(location).then((response) => {
            resolve(response !== 'Not In Store');
          }).catch(() => {
            resolve(false);
          });
        });
      } else {
        resolve(false);
      }
    } catch (error) {
      reject(error);
    }
  })
);

export const fencingItemsGroup = [/^21-8-/];

export const setVirtualAssistanceOnly = (configuratorData, virtualAssistanceType) => {
  const pipfiguratorType = configuratorData?.product?.attributes?.pipfiguratorType;

  return pipfiguratorType === virtualAssistanceType;
};

// Gets the first tier that has been completed for bmsm. If all tiers have been completed returns the last tier.
// Tiers are 0 based
const getCurrentPromoTier = (tiers, cartValue, cartQuantity) => {
  const currentIndex = tiers.findLastIndex(({ minPurchaseAmount, minPurchaseQuantity }) => {
    if (minPurchaseAmount) return cartValue >= minPurchaseAmount;
    if (minPurchaseQuantity) return cartQuantity >= minPurchaseQuantity;
    return false;
  });

  return currentIndex;
};

export const getDiscount = (bmsmPromotion, debouncedQuantity, orderTotal) => {
  if (bmsmPromotion) {
    const currentTier = getCurrentPromoTier(bmsmPromotion?.reward?.tiers, orderTotal, debouncedQuantity);
    if (currentTier !== -1) {
      return bmsmPromotion?.reward?.tiers[currentTier].rewardPercent;
    }
  }
  return 0;
};

export const getLocalStore = (fulfillment) => {
  const locations = fulfillment?.locations || [];
  return locations.length === 1 ? locations[0] : locations.find((location) => location.isAnchor);
};

export const isInstock = (fulfillmentDetails, quantity) => {
  const localStore = getLocalStore(fulfillmentDetails);
  return localStore ? (quantity < localStore?.inventory?.quantity || localStore?.inventory?.quantity === quantity)
    : false;
};

export const getFulfillmentDetails = (fulfillmentOptions, ffmType, serviceType = 'pickup') => {
  const fulfillmentOption = fulfillmentOptions.find((ffmOption) => ffmOption.type === serviceType);
  return (fulfillmentOption?.services || []).find((ffm) => ffm.type === ffmType);
};

export const isFFMAvailable = (fulfillmentOptions, ffmType, serviceType = 'pickup', quantity = null) => {
  const fulfillmentDetails = getFulfillmentDetails(fulfillmentOptions, ffmType, serviceType);
  if (fulfillmentDetails && quantity) {
    return isInstock(fulfillmentDetails, quantity);
  }
  return !!fulfillmentDetails;
};