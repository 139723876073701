/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useState, useEffect } from 'react';
import { Radio } from '@thd-olt-component-react/checkbox';
import { func, bool } from 'prop-types';
import {
  Menu, MenuItem, Button, Typography, List, ListItemButton, ListItemText
} from '@one-thd/sui-atomic-components';
import { ArrowUp, ArrowDown } from '@one-thd/sui-icons';
import { ExperienceContext, useConfigService } from '@thd-nucleus/experience-context';
import { ProductResultsContext } from './ProductResultsContext';
import '../styles/results-sortby.scss';
import prZipCodes from '../maPuertoRicoZipCodes';

const browseSortTypes = [
  {
    name: 'Top Sellers',
    sortby: 'topsellers',
    sortorder: 'desc'
  },
  {
    name: 'Most Popular',
    sortby: 'mostpopular',
    sortorder: 'asc'
  },
  {
    name: 'Price Low to High',
    sortby: 'price',
    sortorder: 'asc'
  },
  {
    name: 'Price High to Low',
    sortby: 'price',
    sortorder: 'desc'
  },
  {
    name: 'Top Rated Products',
    sortby: 'toprated',
    sortorder: 'asc'
  }
];

const searchSortTypes = [
  {
    name: 'Best Match',
    sortby: 'bestmatch',
    sortorder: 'asc'
  },
  {
    name: 'Top Sellers',
    sortby: 'topsellers',
    sortorder: 'desc'
  },
  {
    name: 'Price Low to High',
    sortby: 'price',
    sortorder: 'asc'
  },
  {
    name: 'Price High to Low',
    sortby: 'price',
    sortorder: 'desc'
  },
  {
    name: 'Top Rated Products',
    sortby: 'toprated',
    sortorder: 'asc'
  }
];

const ResultsSortBy = ({ drawer, onSortChange, onSortBy, oldDesign }) => {
  const isSortByDeliveryDateEnabled = useConfigService('fs-prop:sort-by-delivery-date-enabled') || false;
  const [currentSort, setCurrentSort] = useState(null);
  const [collapsed, setCollapsed] = useState(true);
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const { data } = useContext(ProductResultsContext);
  const isMajorAppliance = data?.searchModel?.products?.[0]?.identifiers?.productType === 'MAJOR_APPLIANCE';
  const { deliveryZip } = useContext(ExperienceContext);
  const isZipCodeSuppressed = prZipCodes[Number(deliveryZip)] !== undefined;
  const showSoonestAvailable = isMajorAppliance && !isZipCodeSuppressed;
  const { channel } = useContext(ExperienceContext);
  const isMobile = channel === 'mobile';
  const viewPrimaryFilters = useConfigService('fs-prop:isviewPrimaryFiltersEnabled');

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const handleScroll = (event) => {
    setOpen(false);
  };

  const soonestAvailableSortType = {
    name: 'Soonest Available',
    sortby: 'deliverydate',
    sortorder: 'asc'
  };

  const { searchReport } = data?.searchModel || {};

  const {
    startIndex = 0, pageSize = 0, keyword = '', sortBy = '', sortOrder = ''
  } = searchReport || {};

  const sortTypes = keyword ? searchSortTypes : browseSortTypes;
  const getSortNameValue = ({ key, order }) => {
    let sortByDropdown = sortTypes.find((item) => item.sortby === key) || {};
    if (key === 'price') {
      sortByDropdown = sortTypes.find((item) => item.sortby === key && item.sortorder === order) || {};
    }
    const { name, sortby, sortorder } = sortByDropdown;
    return {
      name: name || 'Best Match',
      value: `${sortby}:${sortorder}`
    };
  };
  const [sortValue, setSortValue] = useState(getSortNameValue({
    key: (sortBy && sortBy.toLowerCase()) || '',
    order: (sortOrder && sortOrder.toLowerCase()) || ''
  }).value);

  useEffect(() => {
    const soonestTypeIsInside = browseSortTypes.find(type => type.name === soonestAvailableSortType.name);

    if (showSoonestAvailable && isSortByDeliveryDateEnabled && !soonestTypeIsInside) {
      browseSortTypes.splice(1, 0, soonestAvailableSortType);
    } else if (!showSoonestAvailable && soonestTypeIsInside) {
      const soonestTypeIndex = browseSortTypes.findIndex(type => type.name === soonestAvailableSortType.name);
      browseSortTypes.splice(soonestTypeIndex, 1);
    }

    if (`${sortBy}:${sortOrder}` !== sortValue) {
      setSortValue(getSortNameValue({
        key: (sortBy && sortBy.toLowerCase()) || '',
        order: (sortOrder && sortOrder.toLowerCase()) || ''
      }).value);
    }
  }, [showSoonestAvailable, browseSortTypes, isSortByDeliveryDateEnabled, searchReport]);

  if (!data) return null;

  const currentPage = startIndex && pageSize ? Math.round(startIndex / pageSize) + 1 : 1;
  const pageType = keyword ? 'search' : 'browse';

  const handleSortClick = (evt) => {
    const text = evt.currentTarget.textContent || evt.currentTarget.innerText;
    setCurrentSort(text);
    sortTypes.forEach((type) => {
      if (type.name === text) {
        onSortChange({
          sortby: type.sortby,
          sortorder: type.sortorder,
          name: type.name,
          currentPage,
          page: pageType
        });
      }
    });
    setOpen(false);
  };

  const handleRadioChangeMobile = (event) => {
    if (onSortBy && event?.target?.value) {
      const [sortby, sortorder] = event.target.value.split(':');
      setSortValue(`${sortby}:${sortorder}`);
      onSortBy(`${sortby}:${sortorder}`);
    }
  };

  const handleRadioChange = (event) => {
    if (event?.target?.value) {
      const [sortby, sortorder] = event.target.value.split(':');
      const sortType = sortTypes.find(
        (type) => type.sortby === sortby && type.sortorder === sortorder
      );

      onSortChange({
        sortby,
        sortorder,
        page: pageType,
        currentPage,
        name: sortType?.name || sortTypes[0]?.name
      });
      setSortValue(`${sortby}:${sortorder}`);
    }
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };
  const sortName = getSortNameValue({
    key: (sortBy && sortBy.toLowerCase()) || '',
    order: (sortOrder && sortOrder.toLowerCase()) || ''
  }).name;

  const generateSortByRadios = (radioButtons) => {
    return radioButtons.map((radioButton, index) => {
      const { name, sortby, sortorder } = radioButton;
      const isChecked = sortValue === `${sortby}:${sortorder}`;
      return (
        <div className="sort-by-radio" key={index}>
          <input
            checked={isChecked}
            className="sort-by-radio__input"
            id={`${sortby}-${sortorder}-${index}`}
            name="sort-by-radio"
            type="radio"
            value={`${sortby}:${sortorder}`}
            onChange={isMobile && viewPrimaryFilters && !oldDesign ? handleRadioChangeMobile : handleRadioChange}
          />
          <label className="sort-by-radio__label" htmlFor={`${sortby}-${sortorder}-${index}`}>
            {name}
          </label>
        </div>
      );
    });
  };

  const handleSortByClick = (event) => {
    event.preventDefault();
    setCollapsed(!collapsed);
  };

  return (
    <>
      {!drawer && (
        <div>
          <List disablePadding>
            <ListItemButton
              disableGutters
              id="sort-menu-button"
              onClick={handleToggle}
              aria-expanded={open ? 'true' : undefined}
              aria-haspopup="listbox"
              ara-controls="sort-menu"
              aria-label="sort by"
              data-testid="current-sort"
            >
              <ListItemText
                primary={(
                  <div className="sui-flex sui-justify-end sui-gap-2">
                    <Typography color="subtle">Sort by</Typography>
                    {getSortNameValue(
                      {
                        key: (sortBy && sortBy.toLowerCase()) || '',
                        order: (sortOrder && sortOrder.toLowerCase()) || '',
                      }).name}
                    <div className="sui-mt-1">
                      {
                        open
                          ? <ArrowUp size="small" />
                          : <ArrowDown size="small" />
                      }
                    </div>
                  </div>

                )}
              />
            </ListItemButton>
          </List>
          <div className="sui-flex sui-justify-end">
            <Menu
              id="sort-menu"
              style={{ zIndex: 4000 }}
              open={open}
              anchorEl={anchorRef.current}
              placement="bottom"
              onClose={handleClose}
              onScroll={handleScroll}
              data-testid="sort-dropdown"
              MenuListProps={{ 'aria-labelledby': 'basic-button' }}
            >
              {sortTypes.map((option, index) => (
                <MenuItem
                  key={option.name}
                  value={option.name}
                  selected={
                    getSortNameValue(
                      {
                        key: (sortBy && sortBy.toLowerCase()) || '',
                        order: (sortOrder && sortOrder.toLowerCase()) || '',
                      }).name === option.name
                  }
                  onClick={handleSortClick}
                  data-testid="sort-type"
                >
                  {option.name}
                </MenuItem>
              ))}
            </Menu>
          </div>
        </div>
      )}
      {drawer && (
        <>
          <div className="sort-by" data-component="ResultsSortByDrawer">
            <a className="sort-by__header" href="#" onClick={handleSortByClick}>
              <div className="sort-by__label">Sort by:</div>
              <div className="sort-by__name">
                <span className="sort-by__name--align">{sortName}</span>
                <img
                  className={collapsed ? 'dimension__caret--collapsed' : 'dimension__caret'}
                  alt="caret-icon"
                  src="https://assets.thdstatic.com/images/v1/caret-grey.svg"
                  height="15"
                  width="9"
                />
              </div>
            </a>
          </div>
          {!collapsed && (
            <div className="sort-by__items">
              <Radio.Group>{generateSortByRadios(sortTypes)}</Radio.Group>
            </div>
          )}
        </>
      )}
    </>
  );
};

ResultsSortBy.propTypes = {
  onSortChange: func.isRequired,
  drawer: bool,
  onSortBy: func,
  oldDesign: bool,
};

ResultsSortBy.defaultProps = {
  drawer: false,
  onSortBy: null,
  oldDesign: false,
};

ResultsSortBy.displayName = 'ResultsSortBy';

export { ResultsSortBy };
