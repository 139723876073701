import React from 'react';
import PropTypes from 'prop-types';
import { PickupDrawerInputCart } from './PickupDrawerInputCart';
import { DepotDirectService } from './DepotDirectService';
import { ShippingService } from './ShippingService';
import { ResponseOnly } from './subcomponents/responseOnly/ResponseOnly';
import { useIsMajorAppliance } from '../helpers/useIsMajorAppliance';
import { CheckAvailabilityProvider } from '../CheckAvailabilityContext';
import { dataModel } from './dataModel';

const CheckAvailability = (props) => {
  const {
    itemId,
    itemIds,
    storeId,
    responseOnly,
    isMajorAppliance,
    isPickupDrawer,
    useDrawerModal
  } = props;

  const { isMajorApplianceItemOrItems, loading, error } = useIsMajorAppliance({
    itemId,
    itemIds,
    storeId,
    responseOnly,
    isMajorAppliance,
    isPickupDrawer
  });

  if (!isPickupDrawer && !responseOnly && (loading || error)) return null;

  const getCheckAvailabilityComponent = () => {
    // Conditions for displaying component types
    const showResponseOnly = responseOnly && !isPickupDrawer && !useDrawerModal;
    const showPickUpDrawer = isPickupDrawer && !useDrawerModal;
    const showDepotDirect = !responseOnly && !isPickupDrawer && typeof isMajorApplianceItemOrItems === 'boolean'
      && !loading && !error && isMajorApplianceItemOrItems;

    if (showPickUpDrawer) { return (<PickupDrawerInputCart />); }

    if (showResponseOnly) { return (<ResponseOnly />); }

    if (showDepotDirect) { return (<DepotDirectService />); }

    return (<ShippingService />);
  };

  return (
    <CheckAvailabilityProvider
      {...props} // eslint-disable-line
      isMajorApplianceItemOrItems={isMajorApplianceItemOrItems}
    >
      {getCheckAvailabilityComponent()}
    </CheckAvailabilityProvider>
  );
};

CheckAvailability.propTypes = {
  /** Product ID */
  itemId: PropTypes.string,
  /** Product ID's for checking availability for a bundle or package */
  itemIds: PropTypes.arrayOf(PropTypes.string),
  /** used in conjunction with responseOnly to associate which api call belongs to the individual item */
  queryIds: PropTypes.arrayOf(PropTypes.string),
  /** while we show the form */
  onError: PropTypes.func,
  /** while we show the form */
  onSubmitForm: PropTypes.func,
  /** Price */
  price: PropTypes.number,
  /** Quantity */
  quantity: PropTypes.number,
  /** ZIP code to check availability */
  zipCode: PropTypes.string,
  /** store id to check availability */
  storeId: PropTypes.string,
  /** for rendering a hidden check availability component that is
   * triggered by the onSubmit event of another CA input */
  responseOnly: PropTypes.bool,
  /** if zipcode exists in cookie or is passed in, check the availability on mount */
  autoCheck: PropTypes.bool,
  /** hides the input from the user and because reactionary to an event */
  hideInput: PropTypes.bool,
  /** can be used in conjunction with hideInput so that the only thing that
   * appears is the availability message when its ready */
  showLoader: PropTypes.bool,
  /** to place a title above the input box */
  inputHeaderTitle: PropTypes.string,
  /** show the response message in this component.
   * false allows user to use custom messaging from callback data */
  defaultMessaging: PropTypes.bool,
  /** specify if this component is used for a bundles availability */
  isBundle: PropTypes.bool,
  /** specify if this component is used for a packages availability */
  isPackage: PropTypes.bool,
  /** Prevent and bypass an unnecessary useProduct call if itemType(s) are already known */
  isMajorAppliance: PropTypes.bool,
  /** Prevent and bypass an unnecessary useProduct call if we just need our drawer input form */
  isPickupDrawer: PropTypes.bool,
  /** hard coding this for now to only render new zip input for twoTile design */
  useDrawerModal: PropTypes.bool,
  /** Handles opening and closing the mobile drawer */
  showCheckAvailability: PropTypes.bool
};

CheckAvailability.defaultProps = {
  itemId: '',
  itemIds: null,
  queryIds: null,
  onError: null,
  onSubmitForm: null,
  price: 0,
  quantity: 1,
  zipCode: '',
  storeId: '121',
  responseOnly: false,
  autoCheck: false,
  hideInput: false,
  showLoader: true,
  inputHeaderTitle: null,
  defaultMessaging: true,
  isBundle: false,
  isPackage: false,
  isMajorAppliance: false,
  isPickupDrawer: false,
  useDrawerModal: false,
  showCheckAvailability: false,
};

CheckAvailability.dataModel = dataModel;
export default CheckAvailability;
