import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  params, string, shape, number, client, extend, useDataModel
} from '@thd-nucleus/data-sources';
import { useTheme } from '@thd-olt-component-react/theme-provider';
import { useStoreId } from '@thd-nucleus/experience-context';
import { Button, IconButton } from '@one-thd/sui-atomic-components';
import { Add, Heart } from '@one-thd/sui-icons';
import { AddToListDrawer } from './drawers/AddToListDrawer';
import { TRACK } from './constants';

const AddToList = (props) => {
  const {
    itemId,
    quantity,
    CustomAddToListActionElement,
    products,
    skipListId,
    isSharedList,
    showCount,
    showIconButton,
    storeId: propStoreId
  } = props;

  const [open, setOpen] = useState(false);
  const [favoriteCount, setFavoriteCount] = useState(0);
  const theme = useTheme(AddToList, props);
  const { hideCount, showPlusSign } = theme.props;
  const defaultStoreId = useStoreId();
  const storeId = propStoreId || defaultStoreId;

  useEffect(() => {
    LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger('add-to-list.ready');
  }, []);

  const { data, variables } = useDataModel('product', {
    variables: {
      itemId
    },
    skip: (!itemId || !showCount)
  });

  const favoriteDetailCount = data?.product?.favoriteDetail?.count;

  useEffect(() => {
    setFavoriteCount(favoriteDetailCount);
  }, [favoriteDetailCount]);

  const count = favoriteCount || favoriteDetailCount;
  const canShowCount = !hideCount && showCount && count;
  let favoriteCountRoundOff = count;
  const maxFavoriteCount = 999;
  if (count && count > maxFavoriteCount) {
    favoriteCountRoundOff = `${(count / 1000).toFixed(1)}k`;
  }

  const closeDrawer = () => {
    setOpen(!open);
  };

  const openDrawer = () => {
    setOpen(true);
    if (window.digitalData?.page?.pageInfo?.pageType === 'pip') {
      window.LIFE_CYCLE_EVENT_BUS.trigger(TRACK.BUTTON_CLICK, {});
    }
    LIFE_CYCLE_EVENT_BUS.trigger(TRACK.OPEN_DRAWER, {});
  };

  const favButtonWrapperClass = classNames({
    'sui-pr-2': canShowCount
  });

  return (
    <span data-component="AddToList">
      {showIconButton
        ? (
          <div className={favButtonWrapperClass}>
            <IconButton
              aria-label="Add To List"
              size="large"
              icon={showPlusSign ? Add : Heart}
              onClick={openDrawer}
            />
            {canShowCount && (<span>{favoriteCountRoundOff}</span>)}
          </div>
        ) : (
          <>
            {CustomAddToListActionElement
              ? (
                <CustomAddToListActionElement onClick={openDrawer} />
              ) : (
                <Button
                  variant="secondary"
                  fullWidth
                  onClick={openDrawer}
                >
                  Add to List
                </Button>
              )}
          </>
        )}
      {
        open && (
          <AddToListDrawer
            itemId={itemId}
            quantity={quantity}
            products={products}
            open={open}
            onClose={closeDrawer}
            skipListId={skipListId}
            isSharedList={isSharedList}
            setFavoriteCount={setFavoriteCount}
            favoriteCount={favoriteCount}
            storeId={storeId}
          />
        )
      }
    </span>
  );
};

AddToList.propTypes = {
  itemId: PropTypes.string,
  skipListId: PropTypes.string,
  isSharedList: PropTypes.bool,
  quantity: PropTypes.number,
  CustomAddToListActionElement: PropTypes.func,
  products: PropTypes.arrayOf(PropTypes.shape({
    itemId: PropTypes.string,
    quantity: PropTypes.number,
    price: PropTypes.number
  })),
  showCount: PropTypes.bool,
  showIconButton: PropTypes.bool,
  storeId: PropTypes.string
};

AddToList.defaultProps = {
  itemId: null,
  skipListId: null,
  quantity: 1,
  CustomAddToListActionElement: null,
  products: [],
  isSharedList: false,
  showCount: false,
  showIconButton: false,
  storeId: null
};

AddToList.themeProps = {
  hideCount: PropTypes.bool,
  showPlusSign: PropTypes.bool
};

AddToList.defaultThemeProps = {
  hideCount: false,
  showPlusSign: false
};

AddToList.displayName = 'AddToList';

const favoriteDetailCount = shape({
  count: number()
});

if (favoriteDetailCount.skip) {
  favoriteDetailCount.skip('skipFavoriteCount', false);
}

AddToList.dataModel = extend(
  {
    product: params({ itemId: string().isRequired(), dataSource: string() }).shape({
      itemId: string(),
      dataSources: string(),
      favoriteDetail: client(favoriteDetailCount)
    })
  },
  AddToListDrawer
);

export { AddToList };
