import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { ExperienceContext } from '@thd-nucleus/experience-context';
import { extend } from '@thd-nucleus/data-sources';
import { Sticky } from '@thd-olt-component-react/sticky';
import { ProductResultsContext } from './ProductResultsContext';
import { FilterAndSortPlaceholder } from './FilterAndSort/FilterAndSortPlaceholder';
import { TopFilters } from './FilterAndSort/TopFilters';
import { Drawer } from './FilterAndSort/Drawer';
import { MainFilter } from './FilterAndSort/MainFilter';
import { sortDimensions, getRefinementUrlWithSort } from '../product-results-helpers';
import '../styles/filter-and-sort.scss';

const ResultsFilterAndSort = ({
  onDimensionsChange,
  onSortChange,
  stickyOffset,
  enableMultiStore,
  onDisplay,
  onDisplayChange,
  disableStickyFilter,
  excludePickUpToday,
  isTableView,
  isGetItFastOnPlp,
}) => {
  const { data, loading } = useContext(ProductResultsContext);

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedDimension, setDimension] = useState(null);
  const { nearByStores, store } = useContext(ExperienceContext);

  // if (!data) return null;

  const {
    appliedDimensions, taxonomy, dimensions, searchReport, metadata
  } = data?.searchModel || {};

  const onDisplayData = {
    onDisplay,
    onDisplayChange,
    isStoreDisplay: data?.searchModel?.metadata?.isStoreDisplay || null,
    itemCount: data?.searchModel?.metadata?.productCount?.inStore
  };

  if (!dimensions) {
    return <FilterAndSortPlaceholder numOf={5} />;
  }
  if (dimensions.length === 0) return null;

  const modifiedDimensionsRefinement = excludePickUpToday && disableStickyFilter
    ? dimensions.map((dimension) => {
      if (dimension.label === 'Get It Fast') {
        return {
          ...dimension,
          refinements: dimension?.refinements?.filter?.((refinement) => refinement?.label !== 'Pick Up Today')
        };
      }
      return dimension;
    }).filter((dimension) => {
      return dimension?.refinements?.length > 0;
    })
    : dimensions;

  const { breadCrumbs = [] } = taxonomy || {};

  const toggleDrawer = (label) => {
    setDrawerOpen(!drawerOpen);
    setDimension(label);
  };
  ResultsFilterAndSort.toggleDrawer = toggleDrawer;
  const sortedDimensions = sortDimensions({ dimensions, searchReport, metadata });

  const onRefinementChange = ({ refinement, dimension, onDisplay: displayValue }) => {
    if (onDimensionsChange && dimension) {
      const refinementUrl = getRefinementUrlWithSort({ dimension, metadata, refinement, searchReport });
      const { label: dimensionName } = dimension;
      const updatedRefinement = {
        ...refinement,
        url: refinementUrl,
        dimensionName
      };
      onDimensionsChange({ refinement: updatedRefinement, onDisplay: displayValue });
    } else if (onDimensionsChange && !dimension) {
      onDimensionsChange({ refinement });
    }
  };

  const handleCloseDrawer = () => {
    setDrawerOpen(false);
    document.body.classList.remove('filter-and-sort--no-scroll');
  };

  const onMultiStoreSelectionChange = ({ multiStoreIds }) => {
    const storeSelection = [...multiStoreIds].map((selectedStore) => selectedStore?.toString());
    const updatedNearByStores = storeSelection?.filter((currentStore) => currentStore !== store?.storeId);
    window.LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger('NEARBY_STORES.update', { nearByStores: updatedNearByStores });
  };

  const multiStoreSelection = nearByStores?.length ? [store?.storeId, ...nearByStores] : [];

  return (
    <div className="filter-and-sort" data-component="ResultsFilterAndSort">
      <Drawer
        appliedDimensions={appliedDimensions}
        breadCrumbs={breadCrumbs}
        isOpen={drawerOpen}
        onSortByChange={onSortChange}
        onRefinementChange={onRefinementChange}
        metadata={metadata}
        normalizedDimensions={sortedDimensions}
        searchReport={searchReport}
        toggleDrawer={toggleDrawer}
        onDisplayData={onDisplayData}
        loading={loading}
        enableMultiStore={enableMultiStore}
        onMultiStoreSelectionChange={onMultiStoreSelectionChange}
        multiStoreSelection={multiStoreSelection}
        dimensionLabel={selectedDimension}
        isTableView={isTableView}
      />
      {!disableStickyFilter ? (
        <div className="filter-and-sort__sticky-wrapper">
          <Sticky
            animation="slide"
            className="filter-and-sort--sticky"
            offsetMargin={stickyOffset}
            position="top"
            stickyType="showOnScrollUp"
            visibleByDefault
          >
            <TopFilters
              dimensions={dimensions}
              handleCloseDrawer={handleCloseDrawer}
              isOpen={drawerOpen}
              onChange={onRefinementChange}
              maxDimension={4}
              toggleDrawer={toggleDrawer}
              enableMultiStore={enableMultiStore}
              onDisplayData={onDisplayData}
              onMultiStoreSelectionChange={onMultiStoreSelectionChange}
              multiStoreSelection={multiStoreSelection}
            />
          </Sticky>
        </div>
      ) : (
        <TopFilters
          dimensions={modifiedDimensionsRefinement}
          handleCloseDrawer={handleCloseDrawer}
          isOpen={drawerOpen}
          onChange={onRefinementChange}
          maxDimension={4}
          toggleDrawer={toggleDrawer}
          enableMultiStore={enableMultiStore}
          onDisplayData={onDisplayData}
          onMultiStoreSelectionChange={onMultiStoreSelectionChange}
          multiStoreSelection={multiStoreSelection}
        />
      )}

      {
        !isGetItFastOnPlp
        && (
          <MainFilter
            dimensions={sortedDimensions}
            enableMultiStore={enableMultiStore}
            multiStoreSelection={multiStoreSelection}
            onChange={onDisplay === 'true' ? onDisplayChange : onRefinementChange}
            onDisplayData={onDisplayData}
            onMultiStoreSelectionChange={onMultiStoreSelectionChange}
          />
        )
      }
    </div>
  );
};

ResultsFilterAndSort.propTypes = {
  onDimensionsChange: PropTypes.func.isRequired,
  onSortChange: PropTypes.func.isRequired,
  stickyOffset: PropTypes.number,
  enableMultiStore: PropTypes.bool,
  onDisplay: PropTypes.string,
  onDisplayChange: PropTypes.func,
  disableStickyFilter: PropTypes.bool,
  excludePickUpToday: PropTypes.bool,
  isTableView: PropTypes.bool,
  isGetItFastOnPlp: PropTypes.bool,
};

ResultsFilterAndSort.defaultProps = {
  stickyOffset: 0,
  enableMultiStore: false,
  onDisplay: 'false',
  onDisplayChange: null,
  disableStickyFilter: false,
  excludePickUpToday: false,
  isTableView: false,
  isGetItFastOnPlp: false,
};

ResultsFilterAndSort.displayName = 'ResultsFilterAndSort';

ResultsFilterAndSort.dataModel = extend(MainFilter, Drawer);

export { ResultsFilterAndSort };
